import React from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router-dom';
import Home from './containers/home/home';
import SearchResults from './containers/search-results/search-results';
import Registration from './containers/registration/registration';
import LogIn from './containers/log-in/log-in';
import Error from './containers/error/error';
import MyAccount from './containers/my-account/my-account';
import VideoResults from './containers/video-results/video-results';
import ImagesResults from './containers/images-results/images-results';
import NewsResults from './containers/news-results/news-results';
import PasswordReset from './containers/pasword-reset/pasword-reset';
import NewPassword from './containers/new-password/new-password';
import Profile from './containers/profile/profile';
import Causes from './containers/causes/causes';
import Prizes from './containers/prizes/prizes';
import Winners from './containers/winners/winners';
import AboutUs from './containers/about-us/about-us';
import ContactUs from './containers/contact-us/contact-us';
import Faq from './containers/faq/faq';

const _userSeassionIdentifier = "_user";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      prize: { name: '', iconUrl: '' },
      cause: { name: '', iconUrl: '' },
      showComingSoon: true,
      treesSaved: 0,
      co2Saved: 0,
      winners: 0
    };
  }

  componentDidMount() {
    const user = sessionStorage.getItem(_userSeassionIdentifier);
    if (!user) {
      fetch("/api/get-user")
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({ user: result.user });
          sessionStorage.setItem(_userSeassionIdentifier, JSON.stringify(result.user));
        }
      });
    } else {
      this.setState({ user: JSON.parse(user) });
    }

    fetch("/api/get-prize-cause")
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({
            prize: { name: result.data.prize.name, iconUrl: result.data.prize.iconUrl },
            cause: { name: result.data.cause.name, iconUrl: result.data.cause.iconUrl },
            treesSaved: result.data.treesSaved,
            co2Saved: result.data.co2Saved,
            winners: result.data.winners
          });
        }
      });
  }

  render() {
    return (
      <>
        <Switch location={this.props.location}>
          <Route path='/' component={ () => <Home user={ this.state.user } prize={ this.state.prize } cause={ this.state.cause } stats={{treesSaved: this.state.treesSaved, co2Saved: this.state.co2Saved, winners: this.state.winners}} /> } exact={ true } />
          <Route path='/search' component={ () => <SearchResults user={ this.state.user } /> } exact={ true } />
          <Route path='/videos' component={ () => <VideoResults user={ this.state.user } /> } exact={ true } />
          <Route path='/images' component={ () => <ImagesResults user={ this.state.user } /> } exact={ true } />
          <Route path='/news' component={ () => <NewsResults user={ this.state.user } /> } exact={ true } />
          <Route path='/registration' component={ () => <Registration user={ this.state.user } /> } exact={ true } />
          <Route path='/log-in' component={ () => <LogIn user={ this.state.user } setUser={ user => this.setState({ user: user }) } /> } exact={ true } />
          <Route path='/password-reset' component={ () => <PasswordReset /> } exact={ true } />
          <Route path='/set-new-password' component={ () => <NewPassword /> } exact={ true } />
          <Route path='/error' component={ Error } exact={ true } />
          <Route path='/my-account' component={ () => <MyAccount user={ this.state.user } prize={ this.state.prize } cause={ this.state.cause } /> } exact={ true } />
          <Route path='/profile' component={ () => <Profile user={ this.state.user } /> } exact={ true } />
          <Route path='/causes' component={ () => <Causes user={ this.state.user } /> } exact={ true } />
          <Route path='/prizes' component={ () => <Prizes user={ this.state.user } /> } exact={ true } />
          <Route path='/winners' component={ () => <Winners user={ this.state.user } /> } exact={ true } />
          <Route path='/about-us' component={ () => <AboutUs user={ this.state.user } /> } exact={ true } />
          <Route path='/contact-us' component={ () => <ContactUs user={ this.state.user } /> } exact={ true } />
          <Route path='/faq' component={ () => <Faq user={ this.state.user } /> } exact={ true } />

          <Route path='/:id/search'
            component={
              () => <SearchResults
                      user={ this.state.user }
                    />
              }
            exact={ true }
          />
          <Route path='/:id/videos'
            component={
              () => <VideoResults
                      user={ this.state.user }
                    />
              }
            exact={ true }
          />
          <Route path='/:id/images'
            component={
              () => <ImagesResults
                      user={ this.state.user }
                    />
              }
            exact={ true }
          />
          <Route path='/:id/news'
            component={
              () => <NewsResults
                      user={ this.state.user }
                    />
              }
            exact={ true }
          />
          <Route
            path='/:id'
            component={
              () => <Home
                      user={ this.state.user }
                      prize={ this.state.prize }
                      cause={ this.state.cause }
                      stats={{treesSaved: this.state.treesSaved, co2Saved: this.state.co2Saved, winners: this.state.winners}}
                    />
            }
          />
        </Switch>
        {
          this.state.showComingSoon ?
            <div className="coming-soon">
              <p>Coming soon. We're not active yet!</p>
              <p onClick={() => this.setState({showComingSoon: false})}>x</p>
            </div>
          : null
        }
      </>
    );
  }
}

export default withRouter(App);
