import React from 'react';
import styles from './registration-final.module.css';
import RectangularShadowWrap from '../rectangular-shadow-wrap/rectangular-shadow-wrap';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { withRouter, Link as RouterLink } from 'react-router-dom';

const RegistrationFinal = props => {
  return (
    <RectangularShadowWrap style={{ maxWidth: "700px" }}>
      <h2 className={ styles.title }>Uzvy Sign Up: Complete!</h2>
      <p><b>Congratulations, you're a registered member of Uzvy!</b></p>
      <p>Remember, you will never be asked to purchase anything to use or participate in Uzvy. If you are awarded a prize, we will contact you with your invite code.</p>
      <p>All our prizes are funded by search revenues. When you use Bing-powered Uzvy honestly for searching the web, that enables us to fund our prizes and cause.
      Abuse or insincere use of the service will force our service to shut down. Please use Uzvy honestly and help us give fantastic prizes to you, the users of the service, and
      contribute generously to causes that protect children and the environment around the world.</p>
      <p>You can install Uzvy on your mobile phone, add the Uzvy New Tab Page to your Chrome browser, and visit Uzvy directly via Uzvy.com</p>
      <p>To be sure you always get contest entries when you search on Uzvy either log into Uzvy or use this url (it's just for you) to search: 
        <Link component={ RouterLink } to={`/${props.userId}`}> Uzvy.com/{ props.userId }</Link></p>
      <p>You gain contest entries when you search in Uzvy, when you search in Uzvy every day and maintain streak, and when you invite your friends. To get credit for any friends you invite,
      tell them to enter your code when joining Uzvy: { props.inviteCode }</p>
      <p>Welcome to Uzvy. We hope you love it!</p>

      <div className={ styles.buttonWrap }>
        <Button variant="contained" color="primary" fullWidth onClick={ () => props.history.push("/log-in") }>Log in</Button>
      </div>
    </RectangularShadowWrap>
  );
}

export default withRouter(RegistrationFinal);