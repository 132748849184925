import React from 'react';
import styles from './profile-form.module.css';
import { Field, reduxForm, Form } from 'redux-form'
import renderTextField from '../material-ui/TextField';
//import renderSelectField from '../material-ui/Select';
import Button from '@material-ui/core/Button';
//import InputLabel from '@material-ui/core/InputLabel';
//import { DAYS_IN_MONTH, US_STATES } from '../../const';
//import { yearsRangeDateOfBirth } from '../../config';
import { formValidations } from './form-validations';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

class RegistrationForm extends React.Component {
  reCaptchaRef;

  constructor(props) {
    super(props);

    this.state = {
      currentYear: new Date().getFullYear(),
      reCaptchaRef: null,
      oneToTwo: false,
      threeToFive: false,
      sixToNine: false,
      tenPlus: false,
      formValues: {},
      formInitialized: false
    }
  }

  componentDidUpdate() {
    if(!this.state.formInitialized && this.props.invitedByCode) {
      this.props.change("InviteCode", this.props.invitedByCode);
      this.setState({ formInitialized: true });
    }
  }

  setRecaptchaRef(e) {
    this.reCaptchaRef = e;
  }

  normalizePhone(value) {
    if (!value) {
      return value
    }
  
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6,10)}`
  }

  render() {
    return(
      <Form className={ styles.form } noValidate onSubmit={
        this.props.handleSubmit(values => this.props.handleUserDetailsUpdate(values))
      }>
        <div className={ styles.formPage }>
          <div className={ styles.fieldWrap }>
            <Field
              name="FirstName"
              label="First & Middle Name"
              component={ renderTextField }
              disabled
              required
            />
          </div>
          <div className={ styles.fieldWrap }>
            <Field
              name="LastName"
              label="Last Name"
              component={ renderTextField }
              disabled
              required
            />
          </div>
          {/*<div className={ styles.fieldWrap }>
            <Field
              name="City"
              label="City"
              component={ renderTextField }
              required
            />
          </div>
          <div className={ styles.fieldWrap }>
            <Field
              name="State"
              id="State"
              label="State"
              options={ US_STATES.reduce(
                (acc, value) => {
                  acc.push({ value: value, label: value });
                  return acc;
                },
              []) }
              component={ renderSelectField }
              required
            />
          </div>*/}
        </div>
        <div className={ styles.formPage }>
          <div className={ styles.fieldWrap }>
            <Field
              name="Email"
              label="Email"
              component={ renderTextField }
              required
            />
          </div>
          {/*<div className={ styles.fieldWrap }>
            <InputLabel>Birth Month | Year</InputLabel>
            <div className={ styles.month }>
              <Field
                name="Month"
                id="Month"
                label="Month"
                options={ DAYS_IN_MONTH.reduce(
                  (acc, value) => {
                    acc.push({ value: value.monthNo, label: value.month });
                    return acc;
                  },
                []) }
                component={ renderSelectField }
                disabled
                required
              />
            </div>
            <div className={ styles.year }>
              <Field
                name="Year"
                id="Year"
                label="Year"
                options={ new Array(yearsRangeDateOfBirth).fill().reduce(
                  (acc, value, index) => {
                    const startYear = this.state.currentYear - 18; // -18 goes to adjust for the minimum age
                    acc.push({ value: startYear - index, label: startYear - index });
                    return acc;
                  },
                []) }
                component={ renderSelectField }
                disabled
                required
              />
            </div>
          </div>*/}
          <div className={ styles.fieldWrap }>
            <Field
              name="MobilePhoneNumber"
              label="Mobile phone number"
              normalize={ value => this.normalizePhone(value) }
              component={ renderTextField }
              InputProps={{
                startAdornment: <InputAdornment position="start">+1</InputAdornment>
              }}
              required
            />
          </div>
        </div>
        {
          !!this.props.errorMessage ?
            <Alert className={ styles.alert } variant="outlined" severity="error">
              { this.props.errorMessage }
            </Alert>
          : null
        }
        {
          !!this.props.success ?
            <Alert className={ styles.alert } variant="outlined">
              You have successfully updated your profile.
            </Alert>
          : null
        }
        <div className={ styles.buttonWrap }>
          <Button variant="contained" color="primary" type="submit">Save { this.props.isUpdateingUserDetails ? <>&nbsp;&nbsp;<CircularProgress color="inherit" size={18} /></> : null }</Button>
        </div>
      </Form>
    );
  }
};

export default reduxForm({
  form: "RegistrationForm",
  validate: formValidations
})(RegistrationForm);