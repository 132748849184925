import React from 'react';
import styles from './main-search-images-result.module.css';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { sideScroll } from '../../helpers';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const MainSearchImagesResult = props => {
  const scrollElementRef = React.createRef();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [scrollElement, setScrollElement] = React.useState();

  React.useEffect(() => {
    setScrollElement(scrollElementRef.current);
  }, [scrollElementRef]);

  return (
    <div className={ styles.wrap }>
      <Paper className={ styles.container }>
        <h2>Images</h2>
        {
          scrollPosition > 0 ?
            <div
              className={ `${styles.back} ${styles.navigation}` }
              onClick={ () => {
                sideScroll(scrollElement, 'left', 5, 400, 5);
                setScrollPosition(Math.max(scrollPosition - 400, 0));
              }}
            >
              <ChevronLeftIcon fontSize="large" />
            </div>
          : null
        }
        <div ref={ scrollElementRef } className={ styles.imgsWrap }>
          <div className={ styles.imgsScroll }>
            {
              props.items.map(item => {
                return (
                  <a href={ item.contentUrl } target="_blank" rel="noopener noreferrer" key={ item.contentUrl }>
                    <img key={ item.thumbnailUrl } src={ `${item.thumbnailUrl}&h=150` } alt={ item.name } />
                  </a>
                );
              })
            }
          </div>
        </div>
        {
          scrollPosition < scrollElement?.scrollWidth ?
            <div
              className={ `${styles.forward} ${styles.navigation}` }
              onClick={ () => {
                sideScroll(scrollElement, 'right', 5, 400, 5);
                setScrollPosition(Math.min(scrollPosition + 400, scrollElement.scrollWidth));
              }}
            >
              <ChevronRightIcon fontSize="large" />
            </div>
          : null
        }

        <div className={ styles.more }>
          <Link
            component={ RouterLink }
            to={ props.match.params.id ? `/${props.match.params.id}/images?q=${props.searchTerm}&page=1` : `/images?q=${props.searchTerm}&page=1` }
          >
            More results in images
          </Link>
        </div>
      </Paper>
    </div>
  );
}

export default withRouter(MainSearchImagesResult);