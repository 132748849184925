import React from 'react';
import { withRouter } from "react-router-dom";
import { Element } from 'react-scroll';
import line from '../../assets/line.png';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <Header user={this.props.user} size="small" showLogo={true} />
        <div className="home-wrap">
          <Element className="about-section" name="about">
            <p><h2>Contact Us</h2></p>
            <div className="about-content">
                <p className="power">Save forests<br />Win Prizes<br />Search with Uzvy </p>

              <div>
                <p>Email:  Team at FineSage dot com</p> 
                <p>Address: </p>
                <p>Fine Sage</p>
                <p>4521 S Plains Drive</p>
                <p>Sioux Falls, SD  57106</p>
              </div>
            </div>
          <img src={ line } alt="forest" className="responsive" /> 
          </Element>
                    
          <Element className="stripe-section" name="stripe">
            <h2>Save our planet. Get great search results. Uzvy.com</h2>
          </Element>
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(ContactUs);