import React from 'react';
import { withRouter } from "react-router-dom";
import { Element } from 'react-scroll';
import Link from '@material-ui/core/Link';
import line from '../../assets/line.png';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <Header user={this.props.user} size="small" showLogo={true} />
        <div className="home-wrap">
         <Element className="about-section" name="about">
        <p><h2>FAQ</h2></p>
                    <div className="about-content">

            
            <p className="power">Save forests<br />Win Prizes<br />Search with Uzvy </p>

              <div>
                    <h4>How does Uzvy work? </h4>
        <p>
        You gain prize entries every day when you search with Uzvy and when your friends & family search with us.  
        </p>
        
        <h4>What countries are supported for prizes and causes at present?</h4>
        <p>
        US users currently qualify for prizes.  Searches by users in the US, UK, Germany, France, and Canada support causes (protecting the environment).  We will support more countries soon.  
        </p>
        
        <h4>How do I get prize entries when I use Uzvy?</h4>
        <p>
        When you search, when friends and family you invite who join search, and when you search every day in a streak.   
        </p>

        <h4>What are the maximum prize entries I can get?</h4>
        <p>At present, the maximum entries per day is 35.  You gain one daily prize entry for each search you make (up to 5 a day), 
        a bonus 5 or 10 entries if you've maintained a 10 or 30 day streak, and an entry for each search your friends or family make (up to 5 per person and 20 total per day).</p>

        <h4>Where can I use Uzvy?  What devices are supported?</h4>
        <p>You can use uzvy.com in any web browser.  There are iOS and Android Uzvy apps.  For desktop Chrome users, please use the Uzvy Default Search and New Tab browser extensions.  </p>

        <h4>How does Uzvy protect forests and reduce CO2 emissions? </h4>
        <p>
        We work with partners to buy forests and other wild areas to be protected as nature preserves.  
        Our partners include the Rain Forest Trust and other non-profits dedicated to wildlife and forest protection.  
        Deforestation is one of the largest contributors to global warming.  
        </p>

        <h4>When will the prize be a new Tesla?</h4>
        <p>
        Soon!  Invite your friends & family to use Uzvy and it will be even sooner.  
        </p>
        
        <h4>Where are the official rules?</h4>
        <p>Read the official rules here: <Link href="https://www.finesage.com/rules.html" target="_blank">Official Rules</Link> </p>

        <h4>Who are the prize winners?</h4>
        <p>See past prize winners here:  <Link href="https://www.uzvy.com/winners" target="_blank">winners</Link> </p>

        <h4>What forests have been saved by Uzvy?</h4>
        <p>See what forests Uzvy users have protected here:  <Link href="https://www.uzvy.com/causes" target="_blank">causes</Link> </p>

        <h4>What is the current prize?</h4>
        <p>See current and past prizes here:  <Link href="https://www.uzvy.com/prizes" target="_blank">prizes</Link> </p>


        </div>
                  </div>
            
          <img src={ line } alt="forest" class="responsive" /> 
          </Element>
                    
          <Element className="stripe-section" name="stripe">
            <h2>Save our planet. Get great search results. Uzvy.com</h2>
          </Element>
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(ContactUs);