import React from 'react';
import styles from './confirm-email-success.module.css';
import Divider from '@material-ui/core/Divider';
import RectangularShadowWrap from '../rectangular-shadow-wrap/rectangular-shadow-wrap';
import Button from '@material-ui/core/Button';

const ConfirmEmailSuccess = props => {
  return (
    <RectangularShadowWrap style={{ maxWidth: "700px", height: "50vh" }}>
      <h2 className={ styles.title }>Uzvy Sign Up: Email Confirmed Successfully</h2>
      <Divider variant="middle" />
      <div className={ styles.textWrap }>
        <p><b>You have successfully confirmed your email address. Please click the button below to continue account creation.</b></p>
      </div>
      <div className={ styles.buttonWrap }>
        <Button variant="contained" color="primary" onClick={ props.onNext }>Next</Button>
      </div>
    </RectangularShadowWrap>
  );
}

export default ConfirmEmailSuccess;