import React from 'react';
import styles from './video-search-result.module.css';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';

const VideoSearchResult = props => {
  return(
    <div className={ styles.searchResult } >
      <h2>
        <img src={ `https://www.google.com/s2/favicons?sz=20&domain_url=${props.value.contentUrl}` } alt="favicon" />
        <a href={ props.value.contentUrl }>{ props.value.name }</a>
      </h2>
      <p className={ styles.url }>{ props.value.contentUrl }</p>
      <div className={ styles.descWrap }>
        <div className={ styles.img } style={{ backgroundImage: `url(${props.value.thumbnailUrl}&w=160)` }} alt={ props.value.name }>
          <div className={ styles.imgOverlay }>
            <PlayCircleFilledWhiteOutlinedIcon />
          </div>
        </div>
        <p className={ styles.snippet }>{ props.value.description }</p>
      </div>
    </div>
  );
};

export default VideoSearchResult;