import React from 'react';
import { Element } from 'react-scroll';
import Link from '@material-ui/core/Link';
import bingLogo from '../../assets/bing-logo.png';
import logo from '../../assets/logotwo.png';

const Footer = () => {
  return (
    <Element className="footer-section" name="footer">
      <div className="footer-container">
        <img src={ logo } alt ="uzvy logo" width="120px"/>
        <div className="footer-content">
          
          <ul className="footer-links">
            <h2><img src={ bingLogo } alt="bing logo" height="80px" /> bing powered</h2>
            <Link href="https://www.finesage.com/tou.html" target="_blank">Terms & Conditions</Link>
            &nbsp;|&nbsp;<Link href="https://www.finesage.com/privacypolicy.html" target="_blank">Privacy Policy</Link>
            &nbsp;|&nbsp;<Link href="https://www.finesage.com/rules.html" target="_blank">Official Rules</Link>
            <p>NO PURCHASE NECESSARY. VOID WHERE PROHIBITED.
            <div className="small">Uzvy and Uzvy Logo ©2021 Fine Sage. Graphics by <Link href="https://www.vecteezy.com" target="_blank">Vecteezy</Link> and <Link href="https://www.icons8.com" target="_blank">Icons8</Link></div></p>
          </ul>
          
          <p className="footer-mission"></p>
        </div>
      </div>
    </Element>
  );
};

export default Footer;