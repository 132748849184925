import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withRouter } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';

const Menu = props => {
  return (
    <Drawer anchor="right" open={props.open} onClose={props.onClose}>
      <div role="presentation" onClick={props.onClose}>
        <div className="drawer-item-close">
          <Button><CloseIcon /></Button>
        </div>
        <List>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/')}>
            <p>Home</p>
          </ListItem>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/causes')}>
            <p>Causes</p>
          </ListItem>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/winners')}>
            <p>Winners</p>
          </ListItem>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/prizes')}>
            <p>Prizes</p>
          </ListItem>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/about-us')}>
            <p>About Us</p>
          </ListItem>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/contact-us')}>
            <p>Contact Us</p>
          </ListItem>
          <ListItem button className="drawer-item" onClick={() => props.history.push('/faq')}>
            <p>FAQ</p>
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default withRouter(Menu);