import React from 'react';
import styles from './terms-and-condition.module.css';
import RectangularShadowWrap from '../rectangular-shadow-wrap/rectangular-shadow-wrap';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

const TermsAndCondition = props => {
  const [hasAgreed1, setHasAgreed1] = React.useState(false);
  const [hasAgreed2, setHasAgreed2] = React.useState(false);
  const [hasAgreed3, setHasAgreed3] = React.useState(false);
  const [hasAgreed4, setHasAgreed4] = React.useState(false);
  const [hasAgreed5, setHasAgreed5] = React.useState(false);

  const toggleHasAgreed1 = () => {
    setHasAgreed1(!hasAgreed1);
  }
  const toggleHasAgreed2 = () => {
    setHasAgreed2(!hasAgreed2);
  }
  const toggleHasAgreed3 = () => {
    setHasAgreed3(!hasAgreed3);
  }
  const toggleHasAgreed4 = () => {
    setHasAgreed4(!hasAgreed4);
  }
  const toggleHasAgreed5 = () => {
    setHasAgreed5(!hasAgreed5);
  }

  return (
    <RectangularShadowWrap style={{ maxWidth: "700px" }}>
      <div className={ styles.wrap }>
        <h2>Uzvy Sign Up: Terms & Conditions, Privacy policy,<br />Official Rules</h2>
        <Divider variant="middle" />
        <div className={ styles.textWrap }>
          <p><b>Uzvy Search Engine: Terms of Use / End-User Software License Agreement</b></p>
          <p>The Uzvy Search product including the website, web service(s), browser extension(s), mobile browser(s), and related documentation (the "Product") is made available to you under the terms of Uzvy's END-USER SOFTWARE LICENSE AGREEMENT (the "Agreement"). By clicking "Accept" and becoming a member of Uzvy or by using the Uzvy search engine whether as a registered user or unregistered user (the "Users"), you are consenting to be bound by the agreement. If you do not agree to all the terms and conditions of this agreement, do not click accept, do not visit Uzvy, and do not use Uzvy. During the use of the Product, you may be given the option of installing additional components for other software such as the Uzvy New Tab Page for web browsers, Uzvy mobile browsers, or to join or participate in services such as Uzvy's sweepstakes. The installation and or use of those components or services may be governed by additional license agreements. The latest Agreement under which your usage of the Product is bound will be available at www.finesage.com/tou.html and www.finesage.com/privacypolicy.html</p>
          <p>Participation in Uzvy Sweeptakes. If you participate in Uzvy's sweepstakes, you agree to follow and be bound by the Official Rules (the "Official Rules") in addition to this agreement and our Privacy Policy. The latest Official Rules shall be available atwww.finesage.com/rules.html</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;1. LICENSE GRANT. Fine Sage, the parent of the Uzvy Search Engine, grants you a non-exclusive license to use the Product according to its CODE OF CONDUCT, Official Rules, and other guidelines.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;2. CODE OF CONDUCT. Any violation of the code of conduct or other terms contained in this document may result at Fine Sage's sole, final and irrevocable discretion: blocking access to the Uzvy search engine and or other Fine Sage services, ineligibility for sweepstakes prizes, termination of the offending account(s), termination of accounts related or in any way linked to the offending account(s), termination of any prizes won by the offending account(s), retraction or disgorgement of any prizes awarded to offending account(s) and or person(s), and or legal action against the person(s) operating offending account(s).</p>
          <p>By agreeing to these Terms, you're agreeing when using the Product not to:</p>
          <p>Reverse engineeer, copy, modify, create a derivative work of, reverse assemble or otherwise attempt to save data from the Product including all search results, search data, and other data provided by the Product;</p>
          <p>Engage in activity that is fraudulent, false or misleading or attempts in any way to increase your entries in contests or sweepstakes in the Product through fraudulent, misleading or insincere means (e.g., impersonating someone else, creating multiple accounts, creating fake identities, providing false or misleading information, making insincere search queries to gain entries, manipulating the Product to otherwise gain entries through insincere or fraudulent activity);</p>
          <p>Manipulate data or identifiers or circumvent or bypass any restrictions on eligibility for, access to, or availability of the Product including but not limited to multiple account, duplicate account, unique real identity, geographic, location, and age restrictions;</p>
          <p>Circumvent or bypass any rules or assist others to circumvent or bypass any rules regarding the Product's usage, requirements, sweepstakes, contests, and other services;</p>
          <p>Break any law, share any copyrighted content, share any inappropriate content or material (e.g. nudity, graphic violence, offensive images or language, private images, etc.), attack or share any malicious or manipulative software or code such as worm, virus or malware of any sort, engage in hate or violent speech;</p>
          <p>Modify the Product in any manner or form or use modified version(s) of the Product;</p>
          <p>Use any robots or bots to send any data or perform any action in the Product;</p>
          <p>Send any automated queries to the Product or use software which assists in sending any automated or semi-automated queries to the Product;</p>
          <p>Save, reproduce, print, cache, store or distribute content retrieved from the Product including search results, search data, usage data, user data and other users' personal data without the written permission of Fine Sage;</p>
          <p>Sell, assign, sublicense, or otherwise transfer any right in usage of the Product or the user's account in the Product to any other individual or entity;</p>
          <p>Access the product through any other interface other than that provided by Fine Sage, accessing the Product through location-masking tools such as a VPN or proxy, or modifying the Product with other software or browser extensions such as ad blocking tools;</p>
          <p>Access the product through any other interface other than that provided by Fine Sage, accessing the Product through location-masking tools such as a VPN or proxy, or modifying the Product with other software or browser extensions such as ad blocking tools;</p>
          <p>Impersonate the Company, the Product, any person associated with the Product, or any other person or entity or falsely state or misrepresent your affiliation or identify;</p>
          <p>Disrupt, attack or otherwise interfere with the Product's functionality, any servers or networks directly or indirectly powering or connected to the Product or the Uzvy service or site; </p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;3. MICROSOFT AND BING PARTNERSHIP. The Uzvy Search Engine is powered by the Bing Search service which is owned and operated by Microsoft. You agree that your search term(s) shall be relayed along with your IP address and possibly other data such as your HTTP header to Microsoft's Bing Service in order to retrieve search results to display to you. Bing and Microsoft may save your search term(s), IP address, and other identifying data. Their service and use of your personal data is governed by their policies which may be found in these webpages:
            www.microsoft.com/en-us/servicesagreement/
            privacy.microsoft.com/en-us/privacystatement
            docs.microsoft.com/en-us/legal/microsoft-apis/terms-of-use
          </p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;4. ACCOUNT CREATION AND PERSONAL DATA. Fine Sage and its Uzvy service will not sell any personal data nor share any personal data for any third-party's marketing or promotional purposes. To qualify for Uzvy sweepstakes prize(s) through the online entry method, you must create an account with Uzvy and be logged into your account in order to accrue sweepstakes entries. You may always enter Uzvy sweepstakes for free by mailing in an entry form according to the guidelines to the address in the Official Rules. You may always conduct searches in the Uzvy Search Engine without registration or creating an account. When you join the Uzvy service, you agree to make your personal data available to Fine Sage which may include your address, email address, phone number or other personal information. You agree that Fine Sage shall use such data to authenticate your account, authenticate your activity in its service as legitimate, to contact you to notify you in regard to changes in the product or service, and to contact you in the event you win a Sweepstakes prize. You authorize Fine Sage to share your data with third party services only to the extent required to provide the Uzvy service or the actual authentication e.g. to send a text to confirm your mobile phone number, we may need to share your phone number with an SMS service or to send an email to notify you of a change in the product we may need to provide your email address to an email list service.</p>
          <p>ACCOUNT DELETION. Please note that deleting your account may result in a permanent inability to re-activate the account and or to create a new account. Please further note that in order to prevent duplicate accounts or other abuse of our service, we may retain some minimal account information for a period after account deletion.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;5. BINDING ARBITRATION AND CLASS ACTION WAIVER. Except as otherwise noted in this document (see Exception below), by using the Product, you agree to submit to binding arbitration. Any award in an arbitration initiated under this clause shall be limited to monetary damages and shall include no injunction or direction other than the direction to pay a monetary amount. Further, the arbitrator(s) shall have no authority to award punitive, consequential or other damages not measured by the prevailing party's actual damages.</p>
          <p>Class Action Waiver. Any proceedings to resolve any dispute will be conducted solely on an individual basis. No arbitration proceeding may be combined with another without the prior written consent of all parties to the applicable proceeding.</p>
          <p>Class Action Waiver. Any proceedings to resolve any dispute will be conducted solely on an individual basis. No arbitration proceeding may be combined with another without the prior written consent of all parties to the applicable proceeding.</p>
          <p>NO RIGHT TO JURY TRIAL FOR ARBITRAL CLAIM. WHEN YOU AGREE TO JOIN THE PRODUCT OR USE THE PRODUCT AS A REGISTERED OR AN UNREGISTERED USER, YOU HEREBY WAIVE THE RIGHT TO A JURY TRIAL FOR ANY ARBITRAL CLAIM. IF FOR ANY REASON THIS SECTION IS HELD NOT TO APPLY TO AN ARBITRAL CLAIM, YOU STILL AGREE TO WAIVE TRIAL BY JURY FOR THAT ARBITRAL CLAIM. BY USING THE PRODUCT, YOU EXPRESSLY WAIVE YOUR RIGHT TO A JURY TRIAL.</p>
          <p>Exception. Notwithstanding the decision to resolve all Users' disputes through arbitration, this agreement does not preclude claims by Fine Sage to fully enforce its intellectual property rights and/or remedy unfair competition, misappropriation of trade secrets, unauthorized access, fraud or computer fraud, DOS or other related attacks on our service, industrial espionage and/or any other illegal activity.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;6. TERMINATION. If you breach this Agreement your right to use the Product will terminate immediately and without notice, but all provisions of this Agreement except the License Grant (Paragraph 1) will survive termination and continue in effect.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;7. PROPRIETARY RIGHTS. Subject to the foregoing, Fine Sage, the Uzvy Search Engine for itself and on behalf of its licensors, hereby reserves all intellectual property rights in the Product, except for the rights expressly granted in this Agreement. You may not remove or alter any trademark, logo, copyright or other proprietary notice in or on the Product. This license does not grant you any right to use the trademarks, service marks or logos of the Uzvy Search Engine, Fine Sage, its Product and Services, or its licensors.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;8. USE AT YOUR OWN RISK. You accept that by using the Product, you may be exposed to content that is offensive, indecent or objectionable. There is no guarantee of the accuracy, completeness, or usefulness of any linked third party websites or resources in the Product. Your use of the Product is so solely at your own risk. Under no circumstances will Fine Sage be liable in any way for such content, including, but not limited to, for any errors, omissions, offensiveness, indecency or misrepresentations in any images, content, text, or linked third party website, or for any loss or damage of any kind incurred by you as a result of your use of, or reliance on, or any content, images, text, or linked third party website in the Product.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;9. DISCLAIMER OF WARRANTY. THE PRODUCT IS PROVIDED "AS IS" WITH ALL FAULTS. TO THE EXTENT PERMITTED BY LAW, FINE SAGE, LICENSORS HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES THAT THE PRODUCT IS FREE OF DEFECTS, MERCHANTABLE, FIT FOR A PARTICULAR PURPOSE AND NON-INFRINGING. YOU BEAR ENTIRE RISK AS TO SELECTING THE PRODUCT FOR YOUR PURPOSES AND AS TO THE QUALITY AND PERFORMANCE OF THE PRODUCT. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT THE PRODUCT AND ITS SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." FINE SAGE AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS, YOUR USE OF THE PRODUCT AND ACCOMPANYING SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, PRIVATE OR FREE FROM ERROR, ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, AND THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM FINE SAGE, FINE SAGE SPOKESPERSONS OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;10. LIMITATION OF LIABILITY. EXCEPT AS REQUIRED BY LAW, FINE SAGE AND ITS DISTRIBUTORS, DIRECTORS, LICENSORS, CONTRIBUTORS AND AGENTS (COLLECTIVELY, THE "FINE SAGE AND ASSOCIATES") WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THIS AGREEMENT OR THE USE OF OR INABILITY TO USE THE PRODUCT, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH SUCH CLAIM IS BASED. FINE SAGE AND ASSOCIATES' COLLECTIVE LIABILITY UNDER THIS AGREEMENT WILL NOT EXCEED THE FEES PAID BY YOU UNDER THIS LICENSE (IF ANY).</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;11. EXPORT CONTROLS. This license for part or all of the Product including any associated installable software such as browser(s) or browser extension(s) may be subject to all applicable export restrictions. You must comply with all export and import laws and restrictions and regulations of your country or foreign agency or authority relating to the Product and its use.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;12. U.S. GOVERNMENT END-USERS. Some part or all of the Product, some associated service(s) or product(s) such as associated installable software including browser(s) or browser extension(s) may be a "commercial item," as that term is defined in 48 C.F.R. 2.101, consisting of "commercial computer software" and "commercial computer software documentation," as such terms are used in 48 C.F.R. 12.212 (Sept. 1995) and 48 C.F.R. 227.7202 (June 1995). Consistent with 48 C.F.R. 12.212, 48 C.F.R. 27.405(b)(2) (June 1998) and 48 C.F.R. 227.7202, all U.S. Government End Users acquire the Product with only those rights as set forth herein.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;13. MISCELLANEOUS.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;a. This Agreement constitutes the entire agreement between Fine Sage and you concerning the subject matter hereof, and it may only be modified by a written amendment signed by an authorized executive of Fine Sage.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;b. Except to the extent applicable law, if any, provides otherwise, this Agreement will be governed by the laws of the United States, excluding its conflict of law provisions.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;c. This Agreement will not be governed by the United Nations Convention on Contracts for the International Sale of Goods.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;d. Severability. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;e. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;f. Except as required by law, the controlling language of this Agreement is English.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;g. You may not assign your rights under this Agreement to any other person or party; Fine Sage may assign its rights under this Agreement without condition.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;h. This Agreement will be binding upon and will inure to the benefit of the parties, their successors and permitted assigns.</p>
          <p>Uzvy Search Engine: Privacy Policy</p>
          <p>1. Fine Sage offers the Uzvy Search Service and related products which include mobile browser(s) and browser extension(s) as well as the Uzvy Sweepstakes service. This document may be revised and the latest version shall be found here:www.finesage.com/privacypolicy.html</p>
          <p>When you log into Uzvy, Uzvy will use a cookie to authenticate your account and may use cookies to store your preferences, settings, or activity in the service. Uzvy will not use cookies to track your visits or activity on sites other than those operated by Uzvy. Uzvy shares your search queries with Microsoft's Bing service along with identifying information such as your IP address in order to retrieve the requested search results. Microsoft's Bing service may save such data and their usage is governed by their Privacy Policy and Terms and Conditions.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;</p>
          <p>Personal data we collect:</p>
          <p>Fine Sage does not directly collect your browsing data or your browsing history through your usage of any of our products including our browser(s), browser extension(s) and search engine. The only data from our products or services directly retained by Fine Sage is from your usage of the Uzvy Search Service. Data collected through your use of Uzvy may include search term(s), search result click(s), timestamp(s), IP address(es), user agent(s), and http header data. Given the nature of Uzvy's sweepstakes prizes and their monetary value, we must analyze your usage of the service to prevent abuse, block bots, block automated and destructive use of the service, and validate legitimate users' activity in order to award sweepstakes prizes accurately and fairly.</p>
          <p>Other uses may include:</p>
          <p>To improve, troubleshoot or develop our product(s) and service(s). To update our products. To personalize our product for you or make recommendations. To share information about changes in our product(s) or service(s) as well as to occasionally inform you of your activity in our product(s) and service(s). To comply with legal requests when required by law.</p>
          <p>How to delete your personal data:</p>
          <p>You may delete stored search data and most account data by deleting your account with Fine Sage. Some account data may be retained for some time upon account deletion solely to prevent account duplication or abuse.</p>
          <p>Uzvy Sweepstakes Official Rules</p>
          <p>UZVY SWEEPSTAKES OFFICIAL RULES</p>
          <p>This is the home of the Offical Rules of Uzvy's sweepstakes and prizes which are operated by Fine Sage. This may be updated.</p>
          <p>ALL PRIZES ARE GUARANTEED TO BE AWARDED AS OFFERED.</p>
          <p>NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN.</p>
          <p>JOINING UZVY WILL NOT HELP YOU WIN.</p>
          <p>VOID WHERE PROHIBITED.</p>
          <p>ALL OUR PRIZES ARE FUNDED THROUGH SEARCH REVENUES.</p>
          <p>YOUR PERSONAL DATA IS NEVER SOLD BY UZVY.</p>
          <p>MORE ON HOW WE HANDLE YOUR DATA MAY BE FOUND IN OUR PRIVACY POLICY.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;1. DATE OF SWEEPSTAKES. Every day Uzvy shall conduct a sweepstakes from ____ ____, 2020 through ____ ___, 2020. The sweepstakes shall begin at midnight PST the day before and end on 11:59:59 PM PST the following day. The continuation of the daily sweepstakes and confirmation of the daily prize value for any and all following weeks shall be confirmed and updated here.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;2. SWEEPSTAKES DAILY PRIZE. A daily prize of a $100 gift certificate from Amazon, Lululemon or other, select online merchant will be awarded.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;3. TOTAL PRIZE VALUE. $100 per day.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;4. ODDS OF WINNING. The odds of winning depends on the number of entries. There is no limit on the aggregate number of entries. You Have Not Yet Won. All Entries Have The Same Chance Of Winning. The winner(s) have not been identified. We don't know who the winner(s) are. Each entry will have the same chance to win as every other entry.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;5. ELIGIBLITY. You must be a legal United States resident 18 years of age or older to be eligible. Employees, contractors or anyone involved in the direct operation of Uzvy and Fine Sage as well as their immediate families and household members are ineligible to win. You agree you can participate in the sweepstakes only if it is legal in your state. If it is found to be illegal, any awarded prizes will be forefeited or must be returned to be awarded to another Uzvy user.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;6. HOW TO ENTER THROUGH UZVY SEARCH [the "Product"]. Only registered users may gain sweepstakes entries through Uzvy search. To become a registered user of Uzvy and to win any sweepstakes prize, at present you must be a current, legal resident on the u.s. aged 18 or over and must then sign up at Uzvy.com. Registered Uzvy users gain sweepstakes entries in multiple ways: you gain up to 5 entries per day (one per search) for searching while logged in orwith your Uzvy id, you gain up to 5 entries per day (one per search) for searches performed by users whom you invited to Uzvy for up to 20 entries per day, and you gain 5 or 10 entries on each day you have conducted at least 3 searches for that and the previous 10 or 30 days.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;7. HOW TO ENTER, WRITE-IN ENTRY INSTRUCTIONS. If you are a legal resident of the United States 18 or over eligible for the daily sweepstakes, you may enter a single daily sweepstakes by mailing through the USPS a 3" x 5" postcard to Fine Sage; 4521 S. Plains Drive; Sioux Falls, SD 57106 with the following data hand-written on the postcard: "Uzvy Sweepstakes", your name, your address, your email address, and your mobile phone number. You will be entered in the daily sweepstakes for the day it is received. You will receive the maximum possible 35 entries in that day's sweepstakes. Multiple submissions or entries will be disqualified. Any mechanical or automated creation or submission of entries or use of a third-party service for their creation and or submission is not permitted and will be disqualified.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;8. ENTRY RULES. All entries become the sole property of Fine Sage. Receipt of entries will not be acknowledged or returned. Incomplete, indecipherable or inaccurate entries will be void. Any individual who attempts or otherwise encourages, directly or indirectly, the entry of multiple or false contact information under multiple identities, by cellular phone or email, or uses any device or artifice to enter or encourage, directly or indirectly, multiple or false entries, as determined by the Sweepstakes Entities, will be disqualified. Lost, late, illegible, or incomplete entries will be deemed ineligible and disqualified. A limit of thirty-five entries into this sweepstakes is allowed per individual. Any additional entries submitted by any individual will be deemed ineligible. Any violation of the rules of the sweepstakes including abuse or illegitimate use of Uzvy search will disqualify a user's entries and eligibility. Any automated entry of sweepstakes entries, automated submission of search terms into Uzvy, automated Uzvy account creation, non-meaningful search terms or other abuse of the uzvy service or the sweepstakes will result in ineligibility for any prizes at Uzvy's sole discretion. In case a prize is awarded to a user who it is later discovered has submitted a false identity, created multiple accounts, or broke any other rules as determined by Uzvy at its sole discretion, the prize will be forfeited or returned to Uzvy and awarded to a different Uzvy user. There is no cash value to any sweepstakes entries under any circumstances.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;9. UZVY SEARCH SERVICE RULES. You must agree to search only for that data or information in which you have a genuine interest. You agree not to conduct automated searches, insincere or unnecessary searches, searches for the repeated term, searches for meaningless words, or other abuse of the search service. You agree not to spam the service or create fake or multiple accounts. You agree not to click on links you are not interested in. Clicking on links does not improve your odds of winning nor does excessive searching; rather, this behavior will get your account closed or disabled for prizes. Your account may be suspended or disabled anytime at the sole discretion of Fine Sage if it determines that usage was abusive or otherwise subversive or in violation of the principles or rules herein. You must have adblock disabled for Uzvy. If adblock is enabled, any activity and thus any entries will be disqualfied. Uzvy has the sole right to decide if the randomly selected prize winner is a legitimate user who has provided accurate account information and thus is eligible and meets the requirements to receive the prize. Uzvy may at its sole discretion close any account at any time. All accounts are non-transferable and can not be sold or or given to anyone else.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;10. UZVY CAUSES. Uzvy has no direct association with the non-profit organizations which receive funds from Uzvy causes. Uzvy shall transfer funds to achieve the stated goal as agreed upon with the non-profit but can not guarantee that any cause was achieved or that the non-profit performed the duties as agreed upon.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;11. RANDOM DRAWING. On the day following a sweepstakes, a Winner will be randomly selected from all eligible entries received during the day of the sweepstakes. Uzvy may at its sole discretion choose to award a prize to a different randomly selected user if the first user chosen is disqualified.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;12. WHAT HAPPENS IF YOU WIN. We will contact you to confirm your award and your identity if your entry is selected as the Winner. If we are unable to reach you, we will send you one or more of an email, text and or voicemail. If you do not respond within five days, you will forfeit the prize and we will award the prize to another Uzvy user who shall then be the Winner. You may be required to provide an appropriate photograph of yourself to display on our winners page, a legal form of identification, address proof, a social security or similar identification number, or other details to verify your identity or as required by law. Upon verification and contact, we will instruct you as to how you will receive your prize and approximately when you will receive it. Uzvy reserves the right to take up to 45 days after awarding a prize to purchase the prize for delivery to the winner, though many prizes such as online gift cards may be both purchased and delivered within hours of awarding and verification. You may owe taxes on prizes won. We must report prize value to the IRS when required by law; you must as well when required by law. You agree we may substitute cash value for any prize if we can not award the prize for whatever reason. The prize is non-transferable, non-assignable, and without Uzvy's explicit written consent not redeemable for cash.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;13. PROMOTIONAL RIGHTS. By accepting the Prize, the Winner grants to Uzvy/Fine Sage, a twenty year royalty-free, fee-free right to use the Winner's name, submitted photograph, likeness, image, voice, hometown, and biographical information in advertising, trade and promotion, including on the Internet or other media, without further notice, compensation or permission, except where prohibited by law. You must provide an accurate photograph of yourself that is deemed appropriate by Fine Sage to receive an award. At minimum, a photograph of yourself, your first name, the first letter of your last name, and town and state of residence, and prize award will be displayed on our winners webpage. It is critical for our service to exist that we present all winners publicly to insure credibility and trust in the integrity of our sweepstakes and that all promised prizes are awarded.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;14. NON-DISPARAGEMENT. By participating in the sweepstakes and upon accepting a prize from Uzvy, you agree not to disparage publicly the Uzvy search service, the Uzvy sweepstakes, the process of winner selection, the prize itself provided it is the prize promised and or agreed upon, the time it may take to purchase and deliver the prize provided it is according to our rules herein, the delivery of the prize if it has been delivered or is in the process of being purchased or delivered, or the time it may take to deliver the prize if it's out of Uzvy's control. Any disparagement of Uzvy or this process can result in forfeiture of the prize or the return of the prize to Uzvy at Uzvy's sole discretion. Uzvy may seek additional remedies as specified herein depending on the nature of the disparagement at Uzvy's sole discretion.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;15. PRIZE DELIVERY. There may be a delay between the awarding of a prize and the purchase and delivery of the prize. Uzvy often delivers prizes immediately upon awarding them, but some prizes may take up to 45 days to be purchased by Uzvy and possibly longer to be delivered. Uzvy reserves the right to take up 45 days to purchase any prize that has been awarded. Delivery of a prize may then take longer than 45 days. Some prizes may take considerable time for delivery depending on the prize and vendor e.g. Tesla often takes months to deliver a new car. Uzvy agrees that it will arrange for purchase and delivery of every awarded prize within 45 days of awarding the prize, but we do not control the timelines of the vendors who deliver the actual prizes and their delays.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;16. LICENSE GRANT. Fine Sage, the parent of the Uzvy Search Engine, grants you a non-exclusive license to use the Product according to its OFFICIAL RULES herein and other Terms of Use and Privacy Policy.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;17. CODE OF CONDUCT. Any violation of the code of conduct or other terms contained in this document may result at Fine Sage's sole, final and irrevocable discretion: termination of the offending account(s) termination of accounts related or in anyway linked to the offending account(s), termination of any prizes won by the offending account(s), retraction of any prizes awarded to offending account(s) and or person(s), and or legal action against the person(s) operating offending account(s).</p>
          <p>By agreeing to these Official Rules, you're agreeing when using the Product not to:</p>
          <p>Reverse engineeer, copy, modify, create a derivative work of, reverse assemble or otherwise attempt to save data from the Product including all search results, search data, and other data provided by the Product;</p>
          <p>Engage in activity that is fraudulent, false or misleading or attempts in any way to increase your entries in contests or sweepstakes in the Product through fraudulent, misleading or insincere means (e.g., impersonating someone else, creating multiple accounts, creating fake identities, providing false or misleading information, making insincere search queries to gain entries, manipulating the Product to otherwise gain entries through insincere or fraudulent activity);</p>
          <p>Manipulate data or identifiers or circumvent or bypass any restrictions on eligibility for, access to, or availability of the Product including but not limited to multiple account, duplicate account, unique real identity, geographic, location, and age restrictions;</p>
          <p>Circumvent or bypass any rules or assist others to circumvent or bypass any rules regarding the Product's usage, requirements, sweepstakes, contests, and other services;</p>
          <p>Break any law, share any copyrighted content, share any inappropriate content or material (e.g. nudity, graphic violence, offensive images or language, private images, etc.), attack or share any malicious or manipulative software or code such as worm, virus or malware of any sort, engage in hate or violent speech;</p>
          <p>Modify the Product in any manner or form or use modified version(s) of the Product;</p>
          <p>Use any robots or bots to send any data or perform any action in the Product;</p>
          <p>Send any automated queries to the Product or use software which assists in sending any automated or semi-automated queries to the Product;</p>
          <p>Save, reproduce, print, cache, store or distribute content retrieved from the Product including search results, search data, usage data, user data and other users' personal data without the written permission of Fine Sage;</p>
          <p>Sell, assign, sublicense, or otherwise transfer any right in the Product to any other individual or entity;</p>
          <p>Access the product through any other interface other than that provided by Fine Sage;</p>
          <p>Impersonate the Company, the Product, any person associated with the Product, or any other person or entity or falsely state or misrepresent your affiliation or identify;</p>
          <p>Interfere with or disrupt the Sites' functionalities, Services, or servers and networks connected to Sites, or disobey any requirements, procedures, policies, or regulations of networks connected directly or indirectly to the Sites;</p>
          <p>Use a modified Product or use software to modify the Product such as a tool or browser extension to make automated searches or account activity or an ad blocking tool. Any use of an ad blocker or automated search tool will disqualify any entries and or prizes.</p>
          <p>&nbsp;	&nbsp;	&nbsp;	&nbsp;18. FRAUD, ATTACK, OR ATTEMPTED CORRUPTION OF THE SWEEPSTAKES. If for any reason, (including infection by computer virus, bugs, worms, tampering, unauthorized intervention, fraud, technical failures, lost/delayed data transmission, interruption, deletion, defect or any other cause beyond reasonable control of Sweepstakes Entities and their respective parents, subsidiaries and affiliates, that corrupts or affects the administration, security, fairness, integrity, or proper conduct of the Sweepstakes), and the Sweepstakes is not capable of being conducted as described in these Official Rules, Sweepstakes Entities shall have the right, at their sole discretion, to cancel, terminate, modify or suspend this Sweepstakes, and select the prize winners from entries received prior to action taken, or as otherwise deemed fair and appropriate by Sweepstakes Entities. Sweepstakes Entities reserve the right to prosecute any fraudulent entries to the fullest extent of the law. At the Sweepstakes Entities' sole discretion, Sweepstakes winners and guests may be disqualified at any time. CAUTION: ATTEMPTING TO DEFRAUD OR IN ANY WAY TAMPER WITH OR DELIBERATELY DAMAGE ANY WEBSITE OR UNDERMINE THE CONTENT OR LEGITIMATE OPERATION OF THE SWEEPSTAKES BY ANY PERSON(S) IS A VIOLATION OF CRIMINAL AND CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT BE MADE, SPONSOR WILL DISQUALIFY SUCH PERSON(S), AND SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES (INCLUDING ATTORNEYS' FEES) AND OTHER LEGAL OR EQUITABLE REMEDIES FROM ANY SUCH PERSON(S) TO THE FULLEST EXTENT PERMITTED BY LAW. SPONSOR'S FAILURE TO ENFORCE ANY TERM OF THESE OFFICIAL RULES SHALL NOT CONSTITUTE A WAIVER OF THAT OR ANY OTHER PROVISION.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;19. BINDING ARBITRATION AND CLASS ACTION WAIVER. General. Except as otherwise noted in this document (see Exception), by using the Product, searching in Uzvy, creating an account with Uzvy, entering the sweepstakes or any other usage of any of our product or service, you agree to submit to binding arbitration. Any award in an arbitration initiated under this clause shall be limited to monetary damages and shall include no injunction or direction other than the direction to pay a monetary amount. Further, the arbitrator(s) shall have no authority to award punitive, consequential or other damages not measured by the prevailing partyâ€™s actual damages. Class Action Waiver. Any proceedings to resolve any dispute will be conducted solely on an individual basis. No arbitration proceeding may be combined with another without the prior written consent of all parties to the applicable proceeding. NO RIGHT TO JURY TRIAL FOR ARBITRAL CLAIM. WHEN YOU AGREE TO JOIN THE PRODUCT OR USE THE PRODUCT AS AN UNREGISTERED USER, YOU HEREBY WAIVE THE RIGHT TO A JURY TRIAL FOR ANY ARBITRAL CLAIM. IF FOR ANY REASON THIS SECTION IS HELD NOT TO APPLY TO AN ARBITRAL CLAIM, YOU STILL AGREE TO WAIVE TRIAL BY JURY FOR THAT ARBITRAL CLAIM. BY USING THE PRODUCT, YOU EXPRESSLY WAIVE YOUR RIGHT TO A JURY TRIAL. Exception. Notwithstanding the decision to resolve all Users' disputes through arbitration, this agreement does not preclude claims by Fine Sage to fully enforce its intellectual property rights and/or remedy unfair competition, misappropriation of trade secrets, unauthorized access, fraud or computer fraud, DOS or other related attacks on our service, industrial espionage and/or any other illegal activity.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;20. TERMINATION. If you breach this Agreement your right to use the Product will terminate immediately and without notice, but all provisions of this Agreement except the License Grant will survive termination and continue in effect.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;21. PROPRIETARY RIGHTS. Subject to the foregoing, the Uzvy Serch Engine for itself and on behalf of its licensors including Fine Sage, hereby reserves all intellectual property rights in the Product, except for the rights expressly granted in this Agreement. You may not remove or alter any trademark, logo, copyright or other proprietary notice in or on the Product. This license does not grant you any right to use the trademarks, service marks or logos of the Uzvy Search Engine or its licensors.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;22. USE AT YOUR OWN RISK. You accept that by using the Product, you may be exposed to content that is offensive, indecent or objectionable. There is no guarantee of the accuracy, completeness, or usefulness of any linked third party websites or resources in the Product. Your use of the Product is so solely at your own risk. Under no circumstances will Fine Sage be liable in any way for such content, including, but not limited to, for any errors, omissions, offensiveness, indecency or misrepresentations in any images, content, text, or linked third party website, or for any loss or damage of any kind incurred by you as a result of your use of, or reliance on, or any content, images, text, or linked third party website in the Product.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;23. INDEMNIFICATION. By entering this Sweepstakes, entrant agrees to release, hold harmless and indemnify Uzvy, Fine Sage and each of their respective parents, subsidiaries, affiliates, contractors, directors, officers, governors, employees and agents against any and all liability, damages or causes of action (however named or described), with respect to or arising out of either (i) entrant's participation in the Sweepstakes; or (ii) the receipt, use or misuse of the prize awarded in the Sweepstakes, including but not limited to any claims for loss, damage to property, personal injury, and/or death which may occur in connection with, preparation for, or travel related to, the Sweepstakes, or claims based on defamation, rights of privacy, rights of publicity or prize delivery. False or deceptive entries, abuse or insincere use of Uzvy search or the Uzvy sweepstakes or other harmful acts will render the entrant ineligible. Sweepstakes Entities are not responsible for printing, human or other inadvertent errors in these Official Rules, elsewhere in any other webpage, promotion materials or otherwise in the offer or administration of this Sweepstakes. The Sweepstakes Entities including Uzvy and Fine Sage make no warranty, guaranty or representation of any kind, and disclaim any implied warranty, concerning any claim arising in connection with participation in this Sweepstakes or any prize awarded.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;24. DISCLAIMER OF WARRANTY. THE PRODUCT IS PROVIDED "AS IS" WITH ALL FAULTS. TO THE EXTENT PERMITTED BY LAW, FINE SAGE, LICENSORS HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES THAT THE PRODUCT IS FREE OF DEFECTS, MERCHANTABLE, FIT FOR A PARTICULAR PURPOSE AND NON-INFRINGING. YOU BEAR ENTIRE RISK AS TO SELECTING THE PRODUCT FOR YOUR PURPOSES AND AS TO THE QUALITY AND PERFORMANCE OF THE PRODUCT. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK AND THAT THE PRODUCT AND ITS SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." FINE SAGE AND ITS LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS, YOUR USE OF THE PRODUCT AND ACCOMPANYING SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, PRIVATE OR FREE FROM ERROR, ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, AND THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM FINE SAGE, FINE SAGE SPOKESPERSONS OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;25. LIMITATION OF LIABILITY. EXCEPT AS REQUIRED BY LAW, FINE SAGE AND ITS DISTRIBUTORS, DIRECTORS, LICENSORS, CONTRIBUTORS AND AGENTS (COLLECTIVELY, THE "FINE SAGE AND ASSOCIATES") WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THIS AGREEMENT OR THE USE OF OR INABILITY TO USE THE PRODUCT, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT OR OTHERWISE) UPON WHICH SUCH CLAIM IS BASED. FINE SAGE AND ASSOCIATES' COLLECTIVE LIABILITY UNDER THIS AGREEMENT WILL NOT EXCEED THE FEES PAID BY YOU UNDER THIS LICENSE (IF ANY).
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;26. WINNER'S LIST. You may find the Winner(s) of the sweepstakes online at ___ . You may request the name(s) of the Winner(s) for any sweepstakes by sending a separate self-addressed, stamped envelope for each of the dates of the sweepstakes for which you would like the winners to Fine Sage; 4521 S. Plains Drive; Sioux Falls, SD 57106.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;27. SPONSOR. The sole sponsor of the Uzvy search service sweepstakes is Fine Sage; 4521 S. Plains Drive; Sioux Falls, SD 57106. Both Uzvy and Fine Sage for the purpose of this agreement refer to Fine Sage which operates the Uzvy search service and sweepstakes.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;28. MISCELLANEOUS.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;a. This Agreement as well as the service's Privacy Policy and Terms and Conditions constitute the entire agreement between Fine Sage and you concerning the subject matter hereof, and it may only be modified by a written amendment signed by an authorized executive of Fine Sage.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;b. Except to the extent applicable law, if any, provides otherwise, this Agreement will be governed by the laws of the United States, excluding its conflict of law provisions.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;c. If any part of this Agreement is held invalid or unenforceable, that part will be construed to reflect the parties' original intent, and the remaining portions will remain in full force and effect.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;d. A waiver by either party of any term or condition of this Agreement or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach thereof.
            <br />&nbsp;	&nbsp;	&nbsp;	&nbsp;&nbsp;	&nbsp;	&nbsp;	&nbsp;e. Except as required by law, the controlling language of this Agreement is English.</p>
        </div>

        <p className={ styles.fineprint }>NO PURCHASE NECESSARY. VOID WHERE PROHIBITED. The Terms, Privacy Policy, and Official Rules are subjected to change. The latest version of each may always
        be found at: finesage.com/terms.html  finesage.com/privacy.html  finesage.com/rules.html</p>
        
        <FormControlLabel
          control={ <Checkbox onChange={ toggleHasAgreed1 } name="agree1" color="primary" /> }
          label="I agree to Uzvy's Terms & Condition, Privacy Policy and Official Rules."
        />
        <FormControlLabel
          control={ <Checkbox checked={ hasAgreed2 } onChange={ toggleHasAgreed2 } name="agree2" color="primary" /> }
          label="All the information I provided to sign up is accurate. I am 18 or over and a legal resident of the U.S."
        />
        <FormControlLabel
          control={ <Checkbox checked={ hasAgreed3 } onChange={ toggleHasAgreed3 } name="agree3" color="primary" /> }
          label="This is my first and only Uzvy Account. I will not make or encourage anyone to make fake or duplicate accounts."
        />
        <FormControlLabel
          control={ <Checkbox checked={ hasAgreed4 } onChange={ toggleHasAgreed4 } name="agree4" color="primary" /> }
          label="I will use Uzvy to make searches that reflect my honest interest in a product, service, person, idea, fact or other datum."
        />
        <FormControlLabel
          control={ <Checkbox checked={ hasAgreed5 } onChange={ toggleHasAgreed5 } name="agree5" color="primary" /> }
          label={
            <p>I will not search on Uzvy simply to gain contest entries.<br />
            I will not use any software tool or bot to use or assist my use of Uzvy.<br />
            I will not make duplicate searches of the same terms repeatedly.
            I will not enter search terms in Uzvy that are meaningless, random or otherwise of no genuine interest to me.</p>
          }
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={ props.onContinue }
            disabled={ !hasAgreed1 || !hasAgreed2 || !hasAgreed3 || !hasAgreed4 || !hasAgreed5 }
          >
            Next
          </Button>
        </div>
      </div>
    </RectangularShadowWrap>
  );
}

export default TermsAndCondition;