
import React from 'react';
import './search-results.css';
import { withRouter, Link as RouterLink } from "react-router-dom";
import SearchResult from '../../components/search-result/search-result';
import Pagination from '../../components/pagination/pagination';
import SearchResultsHeader from '../../components/search-results-header/search-results-header';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import SearchResultSkeleton from '../../components/search-result-skeleton/search-result-skeleton';
import MainSearchBoxResult from '../../components/main-search-box-result/main-search-box-result';
import MainSearchImagesResult from '../../components/main-search-images-results/main-search-images-result';
import TimeSearchResult from '../../components/time-search-result/time-search-result';
import LanguageIcon from '@material-ui/icons/Language';
import { ReactComponent as WikiIcon } from '../../assets/wikipedia-icon.svg';
import Divider from '@material-ui/core/Divider';
import SearchSettings from '../../components/search-settings/search-settings';
import { storage } from '../../helpers';
import {arrowDownKeyCode, arrowUpKeyCode} from '../../components/autocomplete-input/autocomplete-input';

const _webPagesType = "WebPages";
const _newsType = "News";
const _imagesType = "Images";
const _videosType = "Videos";
const _computationType = "Computation";
const _timeZoneType = "TimeZone";

const _freshnessSessionId = "_freshness";
const _safesearchSessionId = "_safesearch";
const _regionSessionId = "_region";
const _rememberSettingsSessionId = "_rememberSettings";

const _storage = storage;

class SearchResults extends React.Component {
  activeResultRef;

  constructor(props) {
    super(props);

   this.state = {
      searchTerm: "",
      page: 1,
      searchResults: {},
      isSearching: false,
      isLoading: true,
      showSettings: false,
      freshness: "All",
      safesearch: "Moderate",
      region: "en-US",
      rememberSettings: false,
      selectedIndex: undefined,
    };

    this.activeResultRef = React.createRef();
  }

  handleKeyDown = event => {
    if (event.keyCode === arrowDownKeyCode) {
      event.preventDefault();
      this.setState({
        selectedIndex: this.state.selectedIndex === undefined ? 0 : Math.min((this.state.selectedIndex || 0) + 1, this.state.searchResults?.rankingResponse?.mainline?.items?.length || 0)
      }, () => this.scrollIntoView());
    } else if (event.keyCode === arrowUpKeyCode) {
      event.preventDefault();
      this.setState({
        selectedIndex: this.state.selectedIndex === undefined ? 0 : Math.max((this.state.selectedIndex || 0) - 1, 0)
      }, () => this.scrollIntoView());
    }
  }

  scrollIntoView() {
    if (this.activeResultRef.current) {
      this.activeResultRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }


   componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const q = params.get("q");
    this.setState({
      searchTerm: q,
      freshness: _storage.getItem(_freshnessSessionId) || "All",
      safesearch: _storage.getItem(_safesearchSessionId) || "Moderate",
      region: _storage.getItem(_regionSessionId) || "en-US",
      rememberSettings: window.localStorage.getItem(_rememberSettingsSessionId) === "true"
    }, () => this.getSearchResults());

    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  addSearchEvent(userId) {
    const params = new URLSearchParams(this.props.location.search);
    fetch("/api/add-search-event", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        userId: userId,
        searchTerm: this.state.searchTerm,
        d: params.get("d"),
        f: params.get("f"),
        o: params.get("o"),
        a: params.get("a"),
        b: params.get("b"),
        i: params.get("i"),
      })
    })
  }



 getSearchResults() {
    const params = new URLSearchParams(this.props.location.search);
    const q = params.get("q");
    const page = params.get("page") ? params.get("page") : 1;
    const notAltered = params.get("notaltered") ? params.get("notaltered") : false;

    fetch(`/api/search?q=${encodeURIComponent(q)}&page=${page}&notaltered=${notAltered}&freshness=${this.state.freshness}&safesearch=${this.state.safesearch}&region=${this.state.region}`)
      .then(response => response.json())
      .then(result => {
        this.setState({
          searchTerm: q,
          page: page,
          searchResults: result.data,
          isSearching: false,
          isLoading: false
        }, () => {
          if (!!this.props.match.params.id && +page === 1) {
            this.addSearchEvent(this.props.match.params.id);
          } else if (!!this.props.user && +page === 1) {
            this.addSearchEvent(this.props.user.id);
          }
        });
      })
      .catch((err) => {console.log(err)}); //this.props.history.push("/error?message=There was an unknown error. Please try again later."));
  }

  render() {
    const sideBarEntity = this.state.searchResults?.entities?.value?.find(entity => entity?.contractualRules?.findIndex(rule => rule.text === "Wikipedia") > -1);



    return (
      <>
        <SearchResultsHeader
          isSearching={ this.state.isSearching }
          searchTerm={ this.state.searchTerm }
          user={ this.props.user }
          explicitUserId={ this.props.match.params.id }
          handleSettingsClick={ () => this.setState({ showSettings: !this.state.showSettings }) }
          settingsActive={ this.state.showSettings }
          menuType="search"
          handleSearch={ data => {
            this.setState({ isSearching: true });
            if (this.props.match.params.id) {
              this.props.history.push(`/${this.props.match.params.id}/search?q=${encodeURIComponent(data.q)}&page=1`);
            } else {
              this.props.history.push(`/search?q=${encodeURIComponent(data.q)}&page=1`);
            }
          }}
        />
        <SearchSettings
          freshness={ this.state.freshness }
          handleFreshnessChange={ e => {
            this.setState({
              freshness: e.target.value, isLoading: true
            }, () => this.getSearchResults());
            _storage.setItem(_freshnessSessionId, e.target.value, this.state.rememberSettings);
          }}
          safesearch={ this.state.safesearch }
          handleSafesearchChange={ e => {
            this.setState({
              safesearch: e.target.value, isLoading: true
            }, () => this.getSearchResults());
            _storage.setItem(_safesearchSessionId, e.target.value, this.state.rememberSettings);
          }}
          region={ this.state.region }
          handleRegionChange={ e => {
            this.setState({
              region: e.target.value, isLoading: true
            }, () => this.getSearchResults());
            _storage.setItem(_regionSessionId, e.target.value, this.state.rememberSettings);
          }}
          rememberSettings={ this.state.rememberSettings }

          handleRememberSettingsChange={ () => {
            _storage.setItem(_freshnessSessionId, this.state.freshness, !this.state.rememberSettings);
            _storage.setItem(_safesearchSessionId, this.state.safesearch, !this.state.rememberSettings);
            _storage.setItem(_regionSessionId, this.state.region, !this.state.rememberSettings);
            window.localStorage.setItem(_rememberSettingsSessionId, !this.state.rememberSettings, !this.state.rememberSettings);
            this.setState({ rememberSettings: !this.state.rememberSettings });
          }}
          showSettings={ this.state.showSettings }
        />
        {
          this.state.isLoading ?
            <div className="search-results-bg">
              <div className="results-wrap">
                <SearchResultSkeleton />
                <SearchResultSkeleton />
                <SearchResultSkeleton />
                <SearchResultSkeleton />
                <SearchResultSkeleton />
                <SearchResultSkeleton />
                <SearchResultSkeleton />
                <SearchResultSkeleton />
              </div>
            </div>
          : !this.state.searchResults.webPages?.value ?
            <div className="no-search-results-wrap">
              <p>We didn't find any results for: "<b>{ this.state.searchTerm }</b>"</p>
              <p>Please check that all words are spelled correctly or try different keywords.</p>
            </div>
          :
            <div className="search-results-bg">
              <div className="results-wrap">
                {
                  this.state.searchResults.queryContext?.alteredQuery ? 
                    <div className="queryContext">
                      <h2>
                        Including results from&nbsp;
                        <Link
                          component={ RouterLink }
                          to={
                            this.props.match.params.id ?
                              `/${this.props.match.params.id}/search?q=${this.state.searchResults.queryContext.alteredQuery}&page=1`
                            :
                              `/search?q=${this.state.searchResults.queryContext.alteredQuery}&page=1`
                          }
                        >
                          { this.state.searchResults.queryContext.alteredQuery }
                        </Link>
                      </h2>
                      <p>
                        Show results only for&nbsp;
                        <Link
                          component={ RouterLink }
                          to={
                            this.props.match.params.id ?
                              `/${this.props.match.params.id}/search?q=${this.state.searchResults.queryContext.originalQuery}&page=1&notaltered=true`
                            :
                              `/search?q=${this.state.searchResults.queryContext.originalQuery}&page=1&notaltered=true`
                          }
                        >
                          { this.state.searchResults.queryContext.originalQuery }
                        </Link>
                      </p>
                    </div>
                  : null
                }
                {
                  this.state.searchResults.rankingResponse.mainline.items?.map((rank, index) => {
                    if (rank.answerType === _webPagesType) {
                      const result = this.state.searchResults.webPages.value[rank.resultIndex];
                      return (
                        <SearchResult
                          frwdRef={this.state.selectedIndex === index ? this.activeResultRef : undefined}
                          key={ result.url }
                          value={ result }
                          selected={this.state.selectedIndex === index}
                        />
                      )
                    } else if (rank.answerType === _newsType && this.state.searchResults.news.value.filter(item => !!item.image).length > 0) {
                      return (
                        <MainSearchBoxResult
                          frwdRef={this.state.selectedIndex === index ? this.activeResultRef : undefined}

                          key={ rank.answerType }
                          title="News"
                          identifier="news"
                          newsItems={ this.state.searchResults.news.value }
                          searchTerm={ this.state.searchTerm }
                          selected={this.state.selectedIndex === index}
                        />
                      )
                    } else if (rank.answerType === _imagesType) {
                      return (
                        <MainSearchImagesResult
                          frwdRef={this.state.selectedIndex === index ? this.activeResultRef : undefined}
                          key={ rank.answerType }
                          items={ this.state.searchResults.images.value }
                          searchTerm={ this.state.searchTerm }
                          selected={this.state.selectedIndex === index}
                        />
                      )
                    } else if (rank.answerType === _videosType) {
                      return (
                        <MainSearchBoxResult
                          frwdRef={this.state.selectedIndex === index ? this.activeResultRef : undefined}
                          key={ rank.answerType }
                          title="Videos"
                          identifier="videos"
                          videoItems={ this.state.searchResults.videos.value }
                          searchTerm={ this.state.searchTerm }
                          selected={this.state.selectedIndex === index}
                        />
                      )
                    } else if (rank.answerType === _computationType) {
                      const result = this.state.searchResults.computation;
                      return (
                        <Paper key={ rank.answerType } className="computation-result-wrap">
                          <h2>{ result.expression } = { result.value }</h2>
                        </Paper>
                      );
                    } else if (rank.answerType === _timeZoneType) {
                      return <TimeSearchResult key={ rank.answerType } value={ this.state.searchResults.timeZone } />
                    } else {
                      return null;
                    }
                  })
                }
                {
                  this.state.searchResults.relatedSearches && this.state.searchResults.relatedSearches.value.length > 0 ?
                    <div className="related-searches-wrap">
                      <p>Searches related to { this.state.searchTerm }</p>
                      <div className="related-searches-links">
                        {
                          this.state.searchResults.relatedSearches.value?.map((item, index) => 
                            <Link
                              key={ item.displayText }
                              component={ RouterLink }
                              to={
                                this.props.match.params.id ?
                                  `/${this.props.match.params.id}/search?q=${item.displayText}&page=1`
                                :
                                `/search?q=${item.displayText}&page=1`
                              }
                            >
                              { item.displayText }
                              { this.state.searchResults.relatedSearches.value.length !== index + 1 ? <>&nbsp;&nbsp;<b>·</b>&nbsp;&nbsp;</> : null }
                            </Link>
                          )
                        }
                      </div>
                    </div>
                  : null
                }

                <hr />
                <div className="search-results-end-wrap">
                  <p>{ this.state.searchResults.webPages.totalEstimatedMatches?.toLocaleString() } results</p>
                  <Link href="https://privacy.microsoft.com/en-US/privacystatement" target="_blank">Results by Microsoft</Link>
                </div>
        
                <div className="pagination-wrap">
                  <Pagination current={ this.state.page } searchTerm={ this.state.searchTerm } explicitUserId={ this.props.match.params.id } />
                </div>
              </div>

         {
                !!sideBarEntity ?
                  <div className="sidebar-wrap">
                    <Paper>
                      {
                        this.state.searchResults.rankingResponse.sidebar.items
                          .filter(item => item.answerType === "Images")
                          .length > 0
                        ?
                          <div className="sidebar-top-wrap">
                            <div className="sidebar-main-img" style={{ backgroundImage: `url(${this.state.searchResults.images.value[0]?.thumbnailUrl}&w=250)` }} />
                            <div className="sidebar-side-img-wrap">
                              <div className="sidebar-side-img" style={{ backgroundImage: `url(${this.state.searchResults.images.value[1]?.thumbnailUrl}&w=250)` }} />
                              <div className="sidebar-side-img" style={{ backgroundImage: `url(${this.state.searchResults.images.value[2]?.thumbnailUrl}&w=250)` }} />
                              <div className="sidebar-side-img" style={{ backgroundImage: `url(${this.state.searchResults.images.value[3]?.thumbnailUrl}&w=250)` }} />
                              <div className="sidebar-side-img" style={{ backgroundImage: `url(${this.state.searchResults.images.value[4]?.thumbnailUrl}&w=250)` }} />
                            </div>
                            <Link
                              className="more-images-link"
                              component={ RouterLink }
                              to={ this.props.match.params.id ?
                                    `/${this.props.match.params.id}/images?q=${this.state.searchTerm}&page=1`
                                  :
                                    `/images?q=${this.state.searchTerm}&page=1`
                              }
                            >
                              More images
                            </Link>
                          </div>
                        : 
                          sideBarEntity.image ?
                            <div className="sidebar-top-single-image">
                              <img src={ `${sideBarEntity.image?.thumbnailUrl}&h=100` } alt={ sideBarEntity.name } />
                            </div>
                          : null
                      }
                      <div className="sidebar-details">
                        <h2>{sideBarEntity?.name}</h2>
                        <p>{sideBarEntity?.description}</p>
                        {
                          sideBarEntity.url ?
                            <a className="link" href={ sideBarEntity.url  }>
                              <LanguageIcon />
                              <p>Official Website</p>
                            </a>
                              : null
                        }
                        {
                          sideBarEntity?.contractualRules?.findIndex(rule => rule._type === "ContractualRules/LinkAttribution" && rule.text === "Wikipedia") > -1
                        ?
                          <a className="link" href={ sideBarEntity.contractualRules.find(rule => rule._type === "ContractualRules/LinkAttribution").url }>
                            <WikiIcon className="MuiSvgIcon-root" />
                            <p>Wikipedia</p>
                          </a>
                        : null
                        }
                      </div>
                      <Divider />
                      <div className="footer">
                        <p>Data from: { sideBarEntity?.contractualRules.find(rule => rule._type === "ContractualRules/LinkAttribution").text }</p>
                        <p>{ sideBarEntity?.contractualRules.find(rule => rule._type === "ContractualRules/LicenseAttribution").licenseNotice }</p>
                      </div>
                    </Paper>
                  </div> 
                :
                  null
              }
            </div>
        }
      </>
    );
  }
}

export default withRouter(SearchResults);
