import React from 'react';
import './log-in.css';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import Header from '../../components/header/header';
import RectangularShadowWrap from '../../components/rectangular-shadow-wrap/rectangular-shadow-wrap';
import LogInForm from '../../components/log-in-form/log-in-form';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Link from '@material-ui/core/Link';

const _userSeassionIdentifier = "_user";

class LogIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errorMessage: ""
    };
  }

  authenticateUser(values) {
    this.setState({ isLoading: true });

    fetch("/api/authenticate", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        Email: values.Email,
        Password: values.Password
      })
    }).then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({ isLoading: false });
        this.props.setUser(result.user);
        sessionStorage.setItem(_userSeassionIdentifier, JSON.stringify(result.user));
        this.props.history.push("/my-account");
      } else {
        this.setState({
          isLoading: false,
          errorMessage: result.error
        });
      }
    })
    .catch(() => {
      this.setState({
        isLoading: false,
        errorMessage: "Incorrect email or password."
      });
    });
  }

  render() {
    return (
      <div className="log-in">
        <Header></Header>
        <div className="log-in-form-container">
          <RectangularShadowWrap>
            <LockOutlinedIcon className="lock-icon" />
            <h2>Log in</h2>
            <div className="log-in-form-wrap">
              <LogInForm
                isLoading={ this.state.isLoading }
                errorMessage={ this.state.errorMessage }
                handleLogIn={ values => this.authenticateUser(values) }
              />

              <Link className="forgot-password-link" to="/password-reset" component={ RouterLink } variant="body2">
                Forgot password?
              </Link>
              <Link className="sign-up-link" to="/registration" component={ RouterLink } variant="body2">
                Don't have an account? Sign Up!
              </Link>
            </div>
          </RectangularShadowWrap>
        </div>
      </div>
    );
  }
}

export default withRouter(LogIn);