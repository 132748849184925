import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './video-search-result-skeleton.module.css';

const VideoSearchResultSkeleton = props => {
  return (
    <div className={ styles.wrap }>
      <Skeleton animation="wave" width="450px" height="3em" />
      <Skeleton animation="wave" width="300px" height="1em" />

      <div className={ styles.descWrap }>
        <div className={ styles.thumb }>
          <Skeleton animation="wave" width="160px" height="135px" />
        </div>

        <div className={ styles.descLines }>
          <Skeleton animation="wave" width="380px" height="2em" />
          <Skeleton animation="wave" width="330px" height="2em" />
          <Skeleton animation="wave" width="300px" height="2em" />
        </div>
      </div>
    </div>
  );
}

export default VideoSearchResultSkeleton;