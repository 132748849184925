import React, { useEffect } from 'react';
import styles from './autocomplete-input.module.css';

export const arrowDownKeyCode = 40;
export const arrowUpKeyCode = 38;

const AutocompleteInput = props => {
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(-1);
  const [isFocused, setIsFocused] = React.useState(false);
  const canOpen = props.input.value && props.options.length > 0 && isFocused;

  useEffect(() => {
    if(props.isSearching) setIsFocused(false);
  }, [props.isSearching]);

  const onkeydown = e => {
    if(e.keyCode === arrowDownKeyCode && canOpen) {
      if(selectedOptionIndex < props.options.length - 1) {
        props.onOptionSelect(props.options[selectedOptionIndex + 1].value);
        setSelectedOptionIndex(selectedOptionIndex + 1);
      } else {
        props.onOptionSelect(props.options[0].value);
        setSelectedOptionIndex(0);
      }
    } else if(e.keyCode === arrowUpKeyCode && canOpen) {
      if(selectedOptionIndex > 0) {
        props.onOptionSelect(props.options[selectedOptionIndex - 1].value);
        setSelectedOptionIndex(selectedOptionIndex - 1);
      } else {
        props.onOptionSelect(props.options[props.options.length - 1].value);
        setSelectedOptionIndex(props.options.length - 1);
      }
    }
  };

  return(
    <div
      className={ styles.wrap }
      tabIndex="0"
      onBlur={ () => setIsFocused(false) }
      onFocus={ () => setIsFocused(true) }
    >
      <div className={ `${styles.inputWrap} ${canOpen ? styles.extended : ""}` }>
        <input type="text" placeholder="Search" autoComplete="off" onKeyDown={ onkeydown } autoFocus { ...props.input } />
        <button type="submit">&#8981;</button>

        <ul className={ !canOpen ? styles.hide : null }>
        {
          props.options.map((option, index) => {
            return (
              <li
                className={ selectedOptionIndex === index ? styles.selected : "" }
                key={ option.value }
                onClick={ () => {
                  setIsFocused(false);
                  props.onOptionClick(option.value)
                }}
              >
                <p>{ option.value }</p>
              </li>
            );
          })
        }
        </ul>
      </div>
    </div>
  );
};

export default AutocompleteInput;