export const DAYS_IN_MONTH = [
  {
    month: "January",
    monthNo: 1,
    days: 31
  },
  {
    month: "February",
    monthNo: 2,
    days: 28
  },
  {
    month: "March",
    monthNo: 3,
    days: 31
  },
  {
    month: "April",
    monthNo: 4,
    days: 30
  },
  {
    month: "May",
    monthNo: 5,
    days: 31
  },
  {
    month: "June",
    monthNo: 6,
    days: 30
  },
  {
    month: "July",
    monthNo: 7,
    days: 31
  },
  {
    month: "August",
    monthNo: 8,
    days: 31
  },
  {
    month: "September",
    monthNo: 9,
    days: 30
  },
  {
    month: "October",
    monthNo: 10,
    days: 31
  },
  {
    month: "November",
    monthNo: 11,
    days: 30
  },
  {
    month: "December",
    monthNo: 12,
    days: 31
  }
];

export const US_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

export const DASHBOARD_PHRASES = [
  "You're doing great!",
  "Keep it up!",
  "Excellent!",
  "Super!",
  "Wow!",
  "Yes!",
  "Great news!",
  "You're getting us closer to our goal!",
  "Has anyone ever told you that you're wonderful?",
  "We love this!",
  "The world thanks you!",
  "Woot!"
];

export const Regions = [
  { country: "Argentina", code: "es-AR" },
  { country: "Australia", code: "en-AU" },
  { country: "Austria", code: "de-AT" },
  { country: "Belgium", code: "nl-BE" },
  { country: "Belgium", code: "fr-BE" },
  { country: "Brazil", code: "pt-BR" },
  { country: "Canada", code: "en-CA" },
  { country: "Canada", code: "fr-CA" },
  { country: "Chile", code: "es-CL" },
  { country: "Denmark", code: "da-DK" },
  { country: "Finland", code: "fi-FI" },
  { country: "France", code: "fr-FR" },
  { country: "Germany", code: "de-DE" },
  { country: "Hong Kong SAR", code: "zh-HK" },
  { country: "India", code: "en-IN" },
  { country: "Indonesia", code: "en-ID" },
  { country: "Italy", code: "it-IT" },
  { country: "Japan", code: "ja-JP" },
  { country: "Korea", code: "ko-KR" },
  { country: "Malaysia", code: "en-MY" },
  { country: "Mexico", code: "es-MX" },
  { country: "Netherlands", code: "nl-NL" },
  { country: "New Zealand", code: "en-NZ" },
  { country: "Norway", code: "no-NO" },
  { country: "People's republic of China", code: "zh-CN" },
  { country: "Poland", code: "pl-PL" },
  { country: "Republic of the Philippines", code: "en-PH" },
  { country: "Russia", code: "ru-RU" },
  { country: "South Africa", code: "en-ZA" },
  { country: "Spain", code: "es-ES" },
  { country: "Sweden", code: "sv-SE" },
  { country: "Switzerland", code: "fr-CH" },
  { country: "Switzerland", code: "de-CH" },
  { country: "Taiwan", code: "zh-TW" },
  { country: "Turkey", code: "tr-TR" },
  { country: "United Kingdom", code: "en-GB" },
  { country: "United States", code: "en-US" }
];