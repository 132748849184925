import React from 'react';
import './causes.css';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import SearchResultsHeader from '../../components/search-results-header/search-results-header';
import { withRouter } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';

class Causes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      causes: null
    };
  }

  componentDidMount() {
    fetch("/api/get-causes")
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({ causes: result.data, isLoading: false });
      }
    })
    .catch(result => {
      this.props.history.push(`/error?message=${result.error ? result.error : "An unknown error has occured. Please try again later."}`);
    });
  }

  render() {
    return (
      <div className="causes-wrap">
        <SearchResultsHeader
          handleSearch={ data => this.props.history.push(`/search?q=${data.q}&page=1`) }
          mainSearchStyle={{transform: "translateX(-50%)",  left: "50%", maxWidth: "600px" }}
          user={ this.props.user }
          hideTabs={ false }
        />

        {
          this.state.isLoading ? 
            <Backdrop className="on-registration-back-drop" open={this.state.isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          :<>  
            <div className="causes-element">
              <Card>
                <CardHeader color="primary">
                  <h2>Uzvy Causes</h2>
                  <h3>Just search the internet like you normally do in Uzvy's search engine to make these causes happen. With more Uzvy users, our goals and support grow.</h3>
                </CardHeader>
                <CardBody>
                  {
                    this.state.causes.map((item, index) => {
                      return (
                        <>
                          { index === 0 ? <h2 className="cause-title">Current Cause</h2> : index === 1 ? <h2 className="cause-title">Past Cause</h2> : null }
                          <div className="cause-container">
                            <img src={ item.IconUrl } alt={ item.Name } />
                            <div className="cause-details">
                              <h2>{ item.Name }</h2>
                              <p>{ item.Description }</p>
                            </div>
                          </div>
                          { index < this.state.causes.length - 1 ? <Divider /> : null }
                        </>
                      )
                    })
                  }
                </CardBody>
              </Card>
            </div>
          </>
        }
        
      </div>
    );
  }
}

export default withRouter(Causes);
