import React from 'react';
import styles from './confirm-email.module.css';
import RectangularShadowWrap from '../rectangular-shadow-wrap/rectangular-shadow-wrap';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';

const ConfirmEmail = props => {
  return (
    <RectangularShadowWrap style={{ maxWidth: "700px", height: "50vh" }}>
      <h2 className={ styles.title }>Uzvy Sign Up: Email Confirmation</h2>
      <Divider variant="middle" />
      <div className={ styles.textWrap }>
        <p><b>Please check your email address and click the confirmation link.</b></p>
        <p>If you don't see the email in your inbox, please check your Spam Folder and mark the email as "Not Spam".</p>
        {
          props.emailResent ?
            <p>Email has been resent. Please check your email.</p>
          :
            <p>Didn't receive email? <Link className={ styles.link } onClick={ props.resendEmail }>Click here to resend.</Link></p>
        }
      </div>
    </RectangularShadowWrap>
  );
}

export default ConfirmEmail;