import React from 'react';
import styles from './search-results-header.module.css';
import MainSearch from '../main-search/main-search';
import Header from '../header/header';
import { withRouter } from "react-router-dom";
import logo from '../../assets/logotwo.png';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import ImageSearchOutlinedIcon from '@material-ui/icons/ImageSearchOutlined';
import OndemandVideoOutlinedIcon from '@material-ui/icons/OndemandVideoOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

const SearchResultsHeader = props => {
  return(
    <Header className={ `${styles.searchResultsHeader} ${props.hideTabs ? styles.noTabs : ""}` } user={ props.user }>
      <img className={ styles.logo } src={ logo } alt="Uzvy Search Logo" onClick={ () => props.history.push(props.explicitUserId ? `/${props.explicitUserId}` : "/") } />
      <div style={ props.mainSearchStyle } className={ styles.mainSearchWrap }>
        <MainSearch
          initialValues={ props.searchTerm ? { q: props.searchTerm } : null }
          size="small" handleSearch={ props.handleSearch }
          isSearching={ props.isSearching }
        />
      </div>
      {
        !props.hideTabs ?
          props.menuType === "search" ? 
            <div className={ styles.menu }>
              <ul>
                <li key="web">
                  <div
                    className={ props.history.location.pathname.includes("/search") ? styles.active : "" }
                    onClick={() => {
                      if(props.match.params.id) {
                        props.history.push(`/${props.match.params.id}/search?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      } else {
                        props.history.push(`/search?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      }
                    }}
                  >
                    <Link><SearchIcon className={ styles.icon } /> Web</Link>
                  </div>
                </li>
                <li key="images">
                  <div
                    className={ props.history.location.pathname.includes("/images") ? styles.active : "" }
                    onClick={() => {
                      if(props.match.params.id) {
                        props.history.push(`/${props.match.params.id}/images?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      } else {
                        props.history.push(`/images?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      }
                    }}
                  >
                    <Link><ImageSearchOutlinedIcon className={ `${styles.icon} ${styles.otherIcon}` } />Images</Link>
                  </div>
                </li>
                <li key="videos">
                  <div
                    className={ props.history.location.pathname.includes("/videos") ? styles.active : "" }
                    onClick={() => {
                      if(props.match.params.id) {
                        props.history.push(`/${props.match.params.id}/videos?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      } else {
                        props.history.push(`/videos?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      }
                    }}
                  >
                    <Link><OndemandVideoOutlinedIcon className={ `${styles.icon} ${styles.otherIcon}` } />Videos</Link>
                  </div>
                </li>
                <li key="news">
                  <div
                    className={ props.history.location.pathname.includes("/news") ? styles.active : "" }
                    onClick={() => {
                      if(props.match.params.id) {
                        props.history.push(`/${props.match.params.id}/news?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      } else {
                        props.history.push(`/news?q=${encodeURIComponent(props.searchTerm)}&page=1`);
                      }
                    }}
                  >
                    <Link><PublicOutlinedIcon className={ `${styles.icon} ${styles.otherIcon}` } />News</Link>
                  </div>
                </li>
                <li key="settings">
                  <div
                    className={ `${styles.settings} ${props.settingsActive ? styles.active : ""}` }
                    onClick={ props.handleSettingsClick }
                  >
                    <Link><SettingsIcon className={ `${styles.icon} ${styles.otherIcon}` } /></Link>
                  </div>
                </li>
              </ul>
            </div>
          :
            <div className={`${styles.menu} ${styles.customMenu}`}>
              <ul>
                <li key="web">
                  <div
                    onClick={() => {
                      props.history.push("/");
                    }}
                  >
                    <Link>Uzvy Home</Link>
                  </div>
                </li>
                <li key="images">
                  <div
                    className={ props.history.location.pathname.includes("/winners") ? styles.active : "" }
                    onClick={() => {
                      props.history.push("/winners");
                    }}
                  >
                    <Link>Winners</Link>
                  </div>
                </li>
                <li key="videos">
                  <div
                    className={ props.history.location.pathname.includes("/prizes") ? styles.active : "" }
                    onClick={() => {
                      props.history.push("/prizes");
                    }}
                  >
                    <Link>Prizes</Link>
                  </div>
                </li>
                <li key="news">
                  <div
                    className={ props.history.location.pathname.includes("/causes") ? styles.active : "" }
                    onClick={() => {
                      props.history.push("/causes");
                    }}
                  >
                    <Link>Causes</Link>
                  </div>
                </li>
              </ul>
            </div>
        :
          null
      }
    </Header>
  );
};

export default withRouter(SearchResultsHeader);