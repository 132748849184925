import React from 'react';
import styles from './search-settings.module.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Regions } from '../../const';

const SearchSettings = props => {
  return (
    <div className={`${styles.settingsWrap} ${props.showSettings ? styles.settingsOpen : styles.settingsHidden}`}>
      <div className={ styles.options }>
        <FormControl className={ styles.settingsSelect }>
          <InputLabel id="freshness-label">Freshness</InputLabel>
          <Select
            labelId="freshness-label"
            id="freshness"
            value={ props.freshness }
            onChange={ props.handleFreshnessChange }
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Day">Last 24 hours</MenuItem>
            <MenuItem value="Week">Last week</MenuItem>
            <MenuItem value="Month">Last month</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={ styles.settingsSelect }>
          <InputLabel id="safesearch-label">Safe search</InputLabel>
          <Select
            labelId="safesearch-label"
            id="safesearch"
            value={ props.safesearch }
            onChange={ props.handleSafesearchChange }
          >
            <MenuItem value="Strict">Strict</MenuItem>
            <MenuItem value="Moderate">Moderate</MenuItem>
            <MenuItem value="Off">Off</MenuItem>
          </Select>
        </FormControl>
        
        <FormControl className={ styles.settingsSelect }>
          <InputLabel id="safesearch-label">Region</InputLabel>
          <Select
            labelId="region-label"
            id="region"
            value={ props.region }
            onChange={ props.handleRegionChange }
          >
            {
              Regions.map(region => {
                return <MenuItem key={ region.code } value={ region.code }><b>{ region.code }</b> &nbsp; { region.country }</MenuItem>;
              })
            }
          </Select>
        </FormControl>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={ props.rememberSettings }
            onChange={ props.handleRememberSettingsChange }
            name="rememberSettings"
          />
        }
        label="Remember my settings"
      />
    </div>
  );
}

export default SearchSettings;