import React from 'react';
import styles from './introduction.module.css';
import RectangularShadowWrap from '../rectangular-shadow-wrap/rectangular-shadow-wrap';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

const Introduction = props => {
  return (
    <RectangularShadowWrap style={{ maxWidth: "700px" }}>
      {
        props.countryCode === 'US' ?
          <div className={ styles.wrap }>
            <h2>Sign Up Introduction</h2>
            <Divider variant="middle" />
            
            <h3>Use Uzvy Search. Win Prizes. Save the World.</h3>
            <p>Search just like you normally do, get fantastic results (via our search partner Bing), make the world better by supporting the environment,
            children and other causes, and gain sweepstakes entries for fantastic prizes including iPads, Surface computers, new cars like Teslas, shares of stock, and more.</p>

            <h3>Real Identity</h3>
            <p>It takes about a minute to sign up. All information you provide must be accurate or any prize you win will be retracted and awarded to someone else.
            Since real, valuable prizes are involved in Uzvy, without these authentication measures, we would quickly be over-run by fake accounts and bots.</p>

            <h3>Strong Privacy</h3>
            <p>Uzvy will never share or sell the personal information you provide when signing up with any third party without your consent e.g. if you win a new Tesla and
            authorize us to deliver it to you, we then share your address with Tesla to schedule the delivery.</p>

            <h3>Present Requirements: 18+ years old and U.S. Resident</h3>
            <p>At present, you must reside in the U.S., be 18 or over, have a mobile phone number, and have a social security number (you will have to provide it if you win a prize).</p>

            <h3>Not in the U.S.?</h3>
            <p>Follow us to know when Uzvy enters your country: <Link href="">twitter</Link>, <Link href="">facebook</Link>, <Link href="#">instagram</Link>.</p>
            <Button variant="contained" color="primary" onClick={ props.onContinue }>Next</Button>
          </div>
          :
          <div className={ styles.wrap }>
            <h2>Sign Up Introduction</h2>
            <Divider variant="middle" />
            <br /><br />
            <p>Please note that at present only U.S. users are eligible to win prizes. We're expanding to more countries soon. Many users from all over the world can still contribute to causes by searching with Uzvy so please create an account and help save the world with great search results from Uzvy.</p>
            <br />
            <Button variant="contained" color="primary" onClick={ props.onContinue }>Next</Button>
          </div>
      }
    </RectangularShadowWrap>
  );
}

export default Introduction;