import React from 'react';
import styles from './main-search-box-result.module.css';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import { sideScroll } from '../../helpers';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';

const MainSearchBoxResult = props => {
  const scrollElementRef = React.createRef();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [scrollElement, setScrollElement] = React.useState();

  React.useEffect(() => {
    setScrollElement(scrollElementRef.current);
  }, [scrollElementRef]);

  return (
    <div className={ styles.wrap }>
      <h2>{ props.title }</h2>
      {
        scrollPosition > 0 ?
          <div
            className={ `${styles.back} ${styles.navigation}` }
            onClick={ () => {
              if (scrollElement.clientWidth < 554) {
                sideScroll(scrollElement, 'left', 5, scrollElement.clientWidth * 0.53, 5);
                setScrollPosition(Math.max(scrollPosition - scrollElement.clientWidth * 0.53, 0));
              } else if (scrollElement.clientWidth > 630){
                sideScroll(scrollElement, 'left', 5, 445, 5);
                setScrollPosition(Math.max(scrollPosition - 445, 0));
              } else {
                sideScroll(scrollElement, 'left', 5, 220, 5);
                setScrollPosition(Math.max(scrollPosition - 220, 0));
              }
            }}
          >
            <ChevronLeftIcon fontSize="large" />
          </div>
        : null
      }
      <div ref={ scrollElementRef } className={ styles.scroll }>
        <div className={ styles.containerWrapper }>
          {
            props.newsItems ? props.newsItems.map(item => {
              if (item.image) {
                return (
                  <Paper key={ item.url } className={ styles.container}>
                    <div className={ styles.thumb } style={{ backgroundImage: `url(${item.image.thumbnail.contentUrl}&w=250)` }} />
                    <Link href={ item.url }>{ item.name }</Link>
                    <p>{ item.provider[0].name }</p>
                  </Paper>
                );
              } else {
                return null;
              }
            })
          :
            props.videoItems.map(item => {
              if (item.thumbnailUrl) {
                return (
                  <Paper key={ item.contentUrl } className={ styles.container}>
                    <div className={ styles.thumb } style={{ backgroundImage: `url(${item.thumbnailUrl}&w=250)` }}>
                      <div className={ styles.imgOverlay }>
                        <PlayCircleFilledWhiteOutlinedIcon />
                      </div>
                    </div>
                    <Link href={ item.contentUrl }>{ item.name }</Link>
                    <p>{ item.publisher[0].name }</p>
                  </Paper>
                );
              } else {
                return null;
              }
            })
          }
        </div>
      </div>
      {
        scrollPosition < scrollElement?.scrollWidth ?
          <div
            className={ `${styles.forward} ${styles.navigation}` }
            onClick={ () => {
              if (scrollElement.clientWidth < 554) {
                sideScroll(scrollElement, 'right', 5, scrollElement.clientWidth * 0.53, 5);
                setScrollPosition(Math.min(scrollPosition + scrollElement.clientWidth * 0.53, scrollElement.scrollWidth));
              } else if (scrollElement.clientWidth > 630){
                sideScroll(scrollElement, 'right', 5, 445, 5);
                setScrollPosition(Math.min(scrollPosition + 445, scrollElement.scrollWidth));
              } else {
                sideScroll(scrollElement, 'right', 5, 220, 5);
                setScrollPosition(Math.min(scrollPosition + 220, scrollElement.scrollWidth));
              }
            }}
          >
            <ChevronRightIcon fontSize="large" />
          </div>
        : null
      }
      <div className={ styles.more }>
        <Link
          component={ RouterLink }
          to={ props.match.params.id ? `/${props.match.params.id}/${props.identifier}?q=${props.searchTerm}&page=1` : `/${props.identifier}?q=${props.searchTerm}&page=1` }
        >
          More results in { props.identifier }
        </Link>
      </div>
    </div>
  );
}

export default withRouter(MainSearchBoxResult);