import React from 'react';
import './password-reset.css';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import Header from '../../components/header/header';
import RectangularShadowWrap from '../../components/rectangular-shadow-wrap/rectangular-shadow-wrap';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: { value: "", isTouched: false, error: "" },
      isLoading: false,
      success: false,
      errorMessage: ""
    };
  }

  requestPasswordReset() {
    this.setState({ isLoading: true, success: false, errorMessage: "" });
    fetch("/api/request-password-reset", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        Email: this.state.email.value
      })
    })
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({
          isLoading: false,
          success: true
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: result.error
        });
      }
    })
    .catch(() => {
      this.setState({
        isLoading: false,
        errorMessage: "An error has occurred. Please try again or contact us."
      });
    });
  }

  render() {
    return (
      <div className="password-reset">
        <Header></Header>
        <div className="password-reset-form-container">
          <RectangularShadowWrap>
            <LockOutlinedIcon className="lock-icon" />
            <h2>Password reset</h2>
            <div className="password-reset-form-wrap">
              <TextField
                name="Email"
                label="Email"
                variant="outlined"
                value={ this.state.email.value }
                onChange={ e => {
                  if(!!e.target.value) {
                    this.setState({ email: { value: e.target.value, isTouched: true, error: "" }});
                  } else {
                    this.setState({ email: { value: e.target.value, isTouched: true, error: "Email is a requred field." }});
                  }
                }}
                onBlur={ e => {
                  if(!!this.state.email.value && this.state.email.isTouched && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)) {
                    this.setState({ email: { ...this.state.email, isTouched: true, error: "Invalid email address." }});
                  }
                }}
                fullWidth
                error={ !!this.state.email.error && this.state.email.isTouched }
                helperText={ !!this.state.email.error && this.state.email.isTouched ? this.state.email.error : null }
                autoComplete="email"
                required
              />

              {
                !!this.state.errorMessage ?
                  <Alert className="alert" variant="outlined" severity="error">
                    { this.state.errorMessage }
                  </Alert>
                : null
              }

              {
                !!this.state.success ?
                  <Alert className="alert" variant="outlined">
                    You have successfully requested a password reset. Please check your email and click the link to set a new password.
                  </Alert>
                : null
              }

              <Button
                className="password-reset-button"
                fullWidth
                variant="contained"
                color="primary"
                disabled={ this.state.success }
                onClick={ () => {
                  if(!this.state.email.error) this.requestPasswordReset()
                }}
              >
                Request password reset { this.state.isLoading ? <>&nbsp;&nbsp;<CircularProgress color="inherit" size={18} /></> : null }
              </Button>

              <Link className="forgot-password-link" to="/log-in" component={ RouterLink } variant="body2">
                Log in
              </Link>
              <Link className="sign-up-link" to="/registration" component={ RouterLink } variant="body2">
                Don't have an account? Sign Up!
              </Link>
            </div>
          </RectangularShadowWrap>
        </div>
      </div>
    );
  }
}

export default withRouter(PasswordReset);