import React from 'react';
import styles from './time-search-result.module.css';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const TimeSearchResult = props => {
  return (
    <Paper className={ styles.wrap }>
      <h2>{ new Date(props.value.primaryCityTime.time).toLocaleTimeString("en-US", { timeZone: "UTC" }) }</h2>
      <p>
        { new Date(props.value.primaryCityTime.time).toLocaleDateString("en-US", { timeZone: "UTC", weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }
        &nbsp;({ props.value.primaryCityTime.utcOffset })
      </p>
      <p>Time in { props.value.primaryCityTime.location }</p>

      {
        props.value.otherCityTimes ?
        <>
          <Divider className={ styles.divider } />
          <Table className={ styles.table } size="small">
            <TableBody>
              {
                props.value.otherCityTimes.map(city => {
                  return (
                    <TableRow key={city.location} hover>
                      <TableCell className={ styles.timeCell } align="left" width="fit-content">
                        <b>{ new Date(city.time).toLocaleTimeString("en-US", { timeZone: "UTC" }) }</b>
                      </TableCell>
                      <TableCell align="left">{ city.location } ({ city.utcOffset })</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </>
        : null
      }
    </Paper>
  );
}

export default TimeSearchResult;