import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './search-result-skeleton.module.css';

const SearchResultSkeleton = props => {
  return (
    <div className={ styles.wrap }>
      <Skeleton animation="wave" width="350px" height="3em" />
      <Skeleton animation="wave" width="200px" height="1em" />
      <Skeleton animation="wave" width="650px" height="2em" />
      <Skeleton animation="wave" width="550px" height="2em" />
    </div>
  );
}

export default SearchResultSkeleton;