export const sideScroll = (element, direction, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(function(){
      if(direction === 'left'){
          element.scrollLeft -= step;
      } else {
          element.scrollLeft += step;
      }
      scrollAmount += step;
      if(scrollAmount >= distance){
          window.clearInterval(slideTimer);
      }
  }, speed);
}

export const storage = {
  getItem: id => {
      return window.sessionStorage.getItem(id) || window.localStorage.getItem(id);
  },
  setItem: (id, value, inLocal) => {
      if (inLocal) {
          window.localStorage.setItem(id, value);
      } else {
          window.sessionStorage.setItem(id, value);
      }
  }
}