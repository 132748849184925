import React from 'react';
import { Field, reduxForm } from 'redux-form'
import AutocompleteInput from '../autocomplete-input/autocomplete-input';
import { autocompleteTimeout } from '../../config';

const MainSearch = props => {
  const [options, setOptions] = React.useState([]);
  const [interval, setInterval] = React.useState();

  return(
    <div>
      <form onSubmit={ props.handleSubmit(props.handleSearch) }>
        <Field
          name="q"
          id="q"
          placeholder="Search..."
          component={ AutocompleteInput }
          size={ props.size }
          isSearching={ props.isSearching }
          options={ options }
          onOptionClick={ option => { props.change("q", option); props.handleSearch({ q: option }); }}
          onOptionSelect={ option => props.change("q", option) }
          onChange={ e => {
            clearTimeout(interval);
            if(e.target.value === "") {
              setOptions([]); 
            } else {
              setInterval(setTimeout(() => {
                fetch(`/api/getSuggestion?hint=${e.target.value}`)
                  .then(response => response.json())
                  .then(data => {
                    setOptions(data.suggestions);
                  });
              }, autocompleteTimeout));
            }
          }}
        />
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'MainSearchForm'
})(MainSearch);