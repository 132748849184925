import React from 'react';
import { withRouter } from "react-router-dom";
import { Element } from 'react-scroll';
import idea from '../../assets/idea.png';
import forest from '../../assets/treesicon.png';
import explode from '../../assets/exp.png';
import line from '../../assets/line.png';
import elephant from '../../assets/elephant.png';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';

class ContactUs extends React.Component {
  render() {
    return (
      <>
        <Header user={this.props.user} size="small" showLogo={true} />
        <div className="home-wrap">
          <Element className="about-section" name="about">
            <p><h2>About Us</h2></p>
          <Element className="how-uzvy-works-section" name="how-uzvy-works">    

          
            <div className="how-uzvy-works-container">
              
              <p>We are a team of engineers working with the Microsoft Bing team to build Uzvy, a private, superior search experience 
              which rewards users with prizes and helps save the planet through protecting
              forests and wildlife around the world.</p>

            <h3><img src={ idea } alt="idea" height="40px" />  Uzvy's Vision  <img src={ idea } alt="idea" height="40px" /></h3>

              <p>Google's search engine earns over $200 million every day. Imagine a world in which half of those funds went to make the world a better place and to
              users themselves. That's Uzvy. <span className="highlight">We're committed to distributing <b>over half of our gross revenues</b> to causes such as protecting the environment and prizes for Uzvy users.</span></p>

              <p>As more people search with Uzvy, we'll save far more wildlife and offer bigger prizes so tell your friends about Uzvy. At just 2 million users, we'll give away a new Tesla every single day and 
              protect millions of acres of forests around the world.</p>
              
              <h3><img src={ forest } alt="forest" height="40px" />  Deforestation  <img src={ forest } alt="forest" height="40px" /></h3>
              
              <p>All searches (from revenue-earning countries) contribute to causes including forest and wildlife preservation.  Uzvy donates a portion of its revenues to organizations like the Rain Forest Trust to buy forests to establish wildlife preserves.</p>
              <p><span className="highlight">70,000 acres of tropical forest are lost every single day.  This deforestation is responsible for 
              as much as 15% of global warming.</span>  If deforestation was a country, it would be the third largest CO2 emitter ahead of the entire EU. 
              Saving existing forests is one of the most cost-efficient means of curbing global warming.</p>   
              
              <h3><img src={ elephant } alt="biodiversity" height="40px" />  Biodiversity  <img src={ elephant } alt="biodiversity" height="40px" /></h3>
   
              <p>Preserving wild forests also protects endangered species and the marvelous, irreplaceable biodiversity of our planet.  
              In the past 100 years, <span className="highlight">the global loss of wildlife was likely over 90%</span>.  For example in 1900, 100,000 tigers lived in the wild while today there are fewer than 4,000 tigers in the wild, a 96% decline.  
              In just the past 25 years, <span className="highlight">coral reefs globally have likely declined over 50%</span>.  
              At least 20,000 species at present are near extinction with one million at risk.  
              “Destroying a rain forest for economic gain is like burning a Renaissance painting to cook a meal,” wrote biologist E. O. Wilson. 
              </p>              
 
               <h3><img src={ explode } alt="impact" height="40px" />  Leadership <img src={ explode } alt="impact" height="40px" /></h3>
   
              <p>Uzvy was founded and is led by Alok Bhardwaj, an online privacy advocate and the founder of the Epic Privacy Browser.  Alok studied philosophy,
              mathematics and music at Princeton University.  In addition to being an a software and technology enthusiast, he is an avid backpacker who has trekked all over North America, Asia and South America.  
              </p>

 
            </div>
          </Element>


          <img src={ line } alt="forest" class="responsive" /> 
          </Element>
                    
          <Footer />
        </div>
      </>
    );
  }
}

export default withRouter(ContactUs);