import React from 'react';
import './my-account.css';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import SearchResultsHeader from '../../components/search-results-header/search-results-header';
import { DASHBOARD_PHRASES } from '../../const';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import NumberCircle from '../../components/number-circle/number-circle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

const getRandomPhrase = () => {
  const randomInt = Math.floor(Math.random() * DASHBOARD_PHRASES.length);
  return DASHBOARD_PHRASES[randomInt];
}

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      entriesStats: {}
    };
  }

  componentDidMount() {
    fetch("/api/get-my-entries-stats")
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({ entriesStats: result.data, isLoading: false });
      } else {
        this.setState({ error: result.error, isLoading: false });
      }
    })
    .catch(result => {
      this.props.history.push(`/error?message=${result.error ? result.error : "An unknown error has occured. Please try again later."}`);
    });
  }

  render() {
    return (
      <div className="my-account-wrap">
        <SearchResultsHeader
          handleSearch={ data => this.props.history.push(`/search?q=${data.q}&page=1`) }
          mainSearchStyle={{ transform: "translateX(-50%)", left: "50%", maxWidth: "600px" }}
          user={ this.props.user }
          hideTabs={ false }
        />

        {
          this.state.isLoading ? 
            <Backdrop className="on-registration-back-drop" open={this.state.isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          :<>
            {
              this.props.user?.countryCode !== 'US' ?
                <Card className="nonUsNotice">
                  <span><ErrorOutline /><p>Sorry, we aren’t offering prizes in your country yet.</p></span>
                </Card>
                : null
            }
            <div className="my-account-element">
              <Card>
                <CardHeader color="primary">
                  <h2>My Search URL</h2>
                  <h3><Link component={ RouterLink } to={`/${this.props.user?.id}`}> uzvy.com/{ this.props.user?.id }</Link></h3>
                </CardHeader>
                <CardBody>
                  <p>Log into Uzvy or use this website address to get entries for your searches.</p>
                </CardBody>
              </Card>
            </div>
            <div className="my-account-element">
              <Card>
                <CardHeader color="primary">
                  <h2>Uzvy Cause</h2>
                  {
                    this.state.entriesStats.percentOfActiveDays < 70 ?
                      <h3>You've been active { this.state.entriesStats.percentOfActiveDays }% of the days so far this month. Being more active on Uzvy is critical to realizing this cause. We need your help!</h3>
                    :
                      <h3>{ getRandomPhrase() } You've been active on { this.state.entriesStats.percentOfActiveDays }% of the days so far this month!</h3>
                  }
                </CardHeader>
                <CardBody>
                  <p>This Month: { this.props.cause.name }</p>
                  <p>Cause Target: 70% of users active on average everyday</p>
                  <p>Trees Saved: {Math.round((this.state.entriesStats.totalSearches || 0) / 1.2)}</p>
                  <p>Tons of CO2 saved: {Math.round(((this.state.entriesStats.totalSearches || 0) / 1.2) * 0.4)}</p>
                </CardBody>
              </Card>
            </div>

            {
              this.props.user?.countryCode === 'US' ?
                <>
                  <div className="my-account-element">
                    <Card>
                      <CardHeader color="primary">
                        <h2>Daily Prize</h2>
                        <div className="giftcard-wrap">
                          <img src={ this.props.prize.iconUrl } alt="50$ Amazon Gift Card" width="50px" /> 
                          <h3>{ this.props.prize.name }</h3>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <p>Next Prize Level (estimate: 2,500 more users needed to unlock): $100 Amazon Gift Card</p>
                        <p>Share Uzvy. Uzvy needs 5,000,000 users to award a new Tesla every single day.</p>
                        <p className="prizes-fineprint">(estimate, no other prize level is guaranteed or yet offered)</p>
                        <Button variant="outlined" color="primary" href="/winners"><EmojiPeopleIcon /> &nbsp;&nbsp; See the Latest Winners</Button>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="my-account-element">
                    <Card>
                      <CardHeader color="primary">
                        <h2>My Entries</h2>
                        <h3>My Total Entries for today: { this.state.entriesStats.total }</h3>
                      </CardHeader>
                      <CardBody>
                        <div className={`entry-wrap ${this.state.entriesStats.today === 0 ? "inactive" : ""}`}>
                          <div className="entry-title">
                            <NumberCircle inactive={ this.state.entriesStats.today === 0 }>{ this.state.entriesStats.today }</NumberCircle>
                            <h3>My Searches: up to 10 entries per day</h3>
                          </div>
                          <div className="entry-description">
                            <p>1 entry per search per day up to 10.</p>
                          </div>
                        </div>
                        <div className={`entry-wrap ${this.state.entriesStats.streak === 0 ? "inactive" : ""}`}>
                          <div className="entry-title">
                            <NumberCircle inactive={ this.state.entriesStats.streak === 0 }>{ this.state.entriesStats.streak }</NumberCircle>
                            <h3>10 or 30 Day Streak Bonus: up to 10 entries per day</h3>
                          </div>
                          <div className="entry-description">
                            <p>Your Streak: { this.state.entriesStats.daysInStreak } days.</p>
                            <p>5 entries for searching 3 or more times today & on each of the past 10 days.</p>
                            <p>10 entries for searching 3 or more times today & on each of the past 30 days.</p>
                          </div>
                        </div>
                        <div className={`entry-wrap ${this.state.entriesStats.inviteBonus === 0 || this.state.entriesStats.today === 0 ? "inactive" : ""}`}>
                          <div className="entry-title">
                            <NumberCircle inactive={ this.state.entriesStats.inviteBonus === 0 || this.state.entriesStats.today === 0 }>
                              { this.state.entriesStats.inviteBonus }
                            </NumberCircle>
                            <h3>Friends & Family Bonus: up to 20 entries per day</h3>
                          </div>
                          <div className="entry-description">
                            <p>1 entry for every search anyone you invited performs on any day that you're also active.</p>
                            <p>Up to 5 bonus entries per invitee per day and up to 20 friends & family bonus entries in total per day.</p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </>
                : null
            }
            <div className="my-account-element">
              <Card>
                <CardHeader color="primary">
                  <h2>Invite your Friends & Family</h2>
                  <h3>Invite Code: { this.props.user?.id }</h3>
                </CardHeader>
                <CardBody>
                  <p>Gain more daily entries & increase prize values.</p>
                  <p>Make sure they enter your invite code when they join Uzvy, or you can send them personalized registration link:</p>
                  <p><Link component={ RouterLink } to={`/registration?invitecode=${this.props.user?.id}`}> uzvy.com/registration?invitecode={ this.props.user?.id }</Link></p>

                  <Divider />

                  <p>Friends & Family who've joined: <b>{ this.state.entriesStats.inviteeCount ? this.state.entriesStats.inviteeCount : 0 }</b></p>
                  { this.state.entriesStats.invitedBy ? <p>Note, you were invited by userid: { this.state.entriesStats.invitedBy }</p> : null }
                </CardBody>
              </Card>
            </div>
          </>
        }
        
      </div>
    );
  }
}

export default withRouter(MyAccount);