import React from 'react';
import './profile.css';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import SearchResultsHeader from '../../components/search-results-header/search-results-header';
import { withRouter } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProfileForm from '../../components/profile-form/profile-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      password: { value: "", isTouched: false, error: "" },
      repeatPassword: { value: "", isTouched: false, error: "" },
      currentPassword: { value: "", isTouched: false, error: "" },
      userDetails: {},
      userDetailsErrorMessage: "",
      userDetailsIsSuccess: false,
      isUpdateingUserDetails: false,
      isUpdateingPassword: false,
      passwordErrorMessage: "",
      passwordIsSuccess: false
    };
  }

  componentDidMount() {
    fetch("/api/get-user-details")
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({ userDetails: result.data, isLoading: false });
      } else {
        this.setState({ error: result.error, isLoading: false });
      }
    })
    .catch(result => {
      this.props.history.push(`/error?message=${result.error ? result.error : "An unknown error has occured. Please try again later."}`);
    });
  }

  updateUserDetails(values) {
    this.setState({ isUpdateingUserDetails: true, userDetailsIsSuccess: false, userDetailsErrorMessage: "" });
    fetch("/api/update-user-details", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        Email: values.Email,
        City: values.City,
        State: values.State,
        MobilePhoneNumber: values.MobilePhoneNumber,
      })
    })
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({
          isUpdateingUserDetails: false,
          userDetailsIsSuccess: true
        });
      } else {
        this.setState({
          isUpdateingUserDetails: false,
          userDetailsErrorMessage: result.error
        });
      }
    })
    .catch(() => {
      this.setState({
        isUpdateingUserDetails: false,
        userDetailsErrorMessage: "An error has occurred. Please try again or contact us."
      });
    });
  }

  changePassword() {
    this.setState({ isUpdateingPassword: true, passwordIsSuccess: false, passwordErrorMessage: "" });
    fetch("/api/set-new-password", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        CurrentPassword: this.state.currentPassword.value,
        NewPassword: this.state.password.value
      })
    })
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({
          isUpdateingPassword: false,
          passwordIsSuccess: true
        });
      } else {
        this.setState({
          isUpdateingPassword: false,
          passwordErrorMessage: result.error
        });
      }
    })
    .catch(() => {
      this.setState({
        isUpdateingPassword: false,
        passwordErrorMessage: "An error has occurred. Please try again or contact us."
      });
    });
  }

  render() {
    return (
      <div className="my-account-wrap">
        <SearchResultsHeader
          handleSearch={data => {
            if (data.q) {
              this.props.history.push(`/search?q=${data.q}&page=1`)
            }
          }}
          mainSearchStyle={{ transform: "translateX(-50%)", left: "50%", maxWidth: "600px" }}
          user={ this.props.user }
          hideTabs={ false }
        />

        {
          this.state.isLoading ? 
            <Backdrop className="on-registration-back-drop" open={this.state.isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          :<>  
            <div className="profile-element">
              <Card>
                <CardHeader color="primary">
                  <h2>Profile</h2>
                  <h3>You can update your address, email and mobile phone number.</h3>
                </CardHeader>
                <CardBody>
                  <ProfileForm
                    initialValues={ this.state.userDetails }
                    isUpdateingUserDetails={ this.state.isUpdateingUserDetails }
                    errorMessage={ this.state.userDetailsErrorMessage }
                    success={ this.state.userDetailsIsSuccess }
                    handleUserDetailsUpdate={ values => this.updateUserDetails(values) }
                  />
                </CardBody>
              </Card>
            </div>
            <div className="profile-element">
              <Card>
                <CardHeader color="primary">
                  <h2>Change password</h2>
                  <h3>After you chnage your password, you will have to log in again.</h3>
                </CardHeader>
                <CardBody>
                  <div className="change-password">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="CurrentPassword"
                      label="Current password"
                      type="password"
                      id="CurrentPassword"
                      onChange={ e => {
                        if(!!e.target.value) {
                          this.setState({ currentPassword: { value: e.target.value, isTouched: true, error: "" }});
                        } else {
                          this.setState({ currentPassword: { value: e.target.value, isTouched: true, error: "Current password is a requred field." }});
                        }
                      }}
                      error={ !!this.state.currentPassword.error && this.state.currentPassword.isTouched }
                      helperText={ !!this.state.currentPassword.error && this.state.currentPassword.isTouched ? this.state.currentPassword.error : null }
                      autoComplete="password"
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="NewPassword"
                      label="New password"
                      type="password"
                      id="Password"
                      onChange={ e => {
                        if(!!e.target.value) {
                          this.setState({ password: { value: e.target.value, isTouched: true, error: "" }});
                          this.setState({ repeatPassword: { ...this.state.repeatPassword, error: "" }});
                        } else {
                          this.setState({ password: { value: e.target.value, isTouched: true, error: "Password is a requred field." }});
                        }
                      }}
                      onBlur={ e => {
                        if(this.state.repeatPassword.isTouched && this.state.password.value !== this.state.repeatPassword.value) {
                          this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "Passwords do not match." }});
                        }
                      }}
                      error={ !!this.state.password.error && this.state.password.isTouched }
                      helperText={ !!this.state.password.error && this.state.password.isTouched ? this.state.password.error : null }
                      autoComplete="new-password"
                    />
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="RepeatPassword"
                      label="Repeat new password"
                      type="password"
                      id="RepeatPassword"
                      onChange={ e => {
                        if(!!e.target.value) {
                          this.setState({ repeatPassword: { value: e.target.value, isTouched: true, error: "" }});
                        } else {
                          this.setState({ repeatPassword: { value: e.target.value, isTouched: true, error: "Repeat password is a requred field." }});
                        }
                      }}
                      onBlur={ e => {
                        if(this.state.repeatPassword.isTouched && this.state.password.value !== this.state.repeatPassword.value) {
                          this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "Passwords do not match." }});
                        } else if(this.state.repeatPassword.isTouched) {
                          if(!this.state.repeatPassword.value.match(/^(?=.*\d)(?=.*[a-z]).+$/)) {
                            this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "Password must include at least one number and one lower case letter." }});
                          } else if(this.state.repeatPassword.value.length < 6) {
                            this.setState({ repeatPassword: { ...this.state.epeatPassword, isTouched: true, error: "Password must have at least 6 characters." }});
                          } else {
                            this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "" }});
                          }
                        }
                      }}
                      error={ !!this.state.repeatPassword.error && this.state.repeatPassword.isTouched }
                      helperText={ !!this.state.repeatPassword.error && this.state.repeatPassword.isTouched ? this.state.repeatPassword.error : null }
                      autoComplete="new-password"
                    />

                    {
                      !!this.state.passwordErrorMessage ?
                        <Alert className="alert" variant="outlined" severity="error">
                          { this.state.passwordErrorMessage }
                        </Alert>
                      : null
                    }
                    {
                      !!this.state.passwordIsSuccess ?
                        <Alert className="alert" variant="outlined">
                          You have successfully changed your password.
                        </Alert>
                      : null
                    }

                    <Button
                      fullWidth
                      variant="contained"
                      onClick={ () => {
                        if(this.state.password.value && this.state.currentPassword.value && this.state.repeatPassword.value
                            && !this.state.password.error && !this.state.repeatPassword.error && !this.state.currentPassword.error) {
                          this.changePassword();
                        }
                      }}
                    >
                      Change password { this.state.isUpdateingPassword ? <>&nbsp;&nbsp;<CircularProgress color="inherit" size={18} /></> : null }
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </>
        }
        
      </div>
    );
  }
}

export default withRouter(Profile);