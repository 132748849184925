export const formValidations = values => {
  const errors = {};
  // full name validations
  if (!values.FirstName) {
    errors.FirstName = "First name is a required field.";
  }

  // last name validations
  if (!values.LastName) {
    errors.LastName = "Last name is a required field.";
  }

  // email validations
  if (!values.Email) {
    errors.Email = "Email is a required field.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
    errors.Email = "Invalid email address.";
  }

  // date of birth balidations
  /*if (!values.Month) {
    errors.Month = "Month is a required field.";
  }
  if (!values.Day) {
    errors.Day = "Day is a required field.";
  }
  if (!values.Year) {
    errors.Year = "Year is a required field.";
  }*/

  // password validations
  if (!values.Password) {
    errors.Password = "Password is a required field.";
  } else if(!values.Password.match(/^(?=.*\d)(?=.*[a-z]).+$/)) {
    errors.Password = "Password must include at least one number and one lower case letter.";
  } else if(values.Password.length < 6) {
    errors.Password = "Password must have at least 6 characters.";
  } 

  if (!values.RepeatPassword) {
    errors.RepeatPassword = "Repeat password is a required field.";
  } else if (values.Password && values.Password !== values.RepeatPassword) {
    errors.RepeatPassword = "Passwords do not match.";
  }

  // address validations
  /*if (!values.AddressLine1) {
    errors.AddressLine1 = "Address is a required field.";
  }
  if (!values.Zip) {
    errors.Zip = "Zip code is a required field.";
  } else if (String(values.Zip).length !== 5) {
    errors.Zip = "Invalid zip code.";
  }
  if (!values.City) {
    errors.City = "City is a required field.";
  }
  if (!values.State) {
    errors.State = "State is a required field.";
  }*/

  return errors
}