import React from 'react';
import styles from './log-in-form.module.css';
import { Field, reduxForm } from 'redux-form'
import renderTextField from '../material-ui/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

const validate = values => {
  const errors = {};

  if (!values.Email) {
    errors.Email = "Email is a required field.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.Email)) {
    errors.Email = "Invalid email address.";
  }

  if (!values.Password) {
    errors.Password = "Password is a required field.";
  }

  return errors;
}

const LogInForm = props => {
  return (
    <form noValidate onSubmit={ props.handleSubmit(props.handleLogIn) }>
      <div className={ styles.fieldWrap }>
        <Field
          name="Email"
          label="Email"
          component={ renderTextField }
          variant="outlined"
          required
        />
      </div>
      <div className={ styles.fieldWrap }>
        <Field
          name="Password"
          label="Password"
          component={ renderTextField }
          variant="outlined"
          type="password"
          required
        />
      </div>
      {
        !!props.errorMessage ?
          <Alert className={ styles.alert } variant="outlined" severity="error">
            { props.errorMessage }
          </Alert>
        : null
      }
      <div className={ styles.buttonWrap }>
        <Button className={ styles.button } fullWidth type="submit" variant="contained" color="primary">
          Log in { props.isLoading ? <>&nbsp;&nbsp;<CircularProgress color="inherit" size={18} /></> : null }
        </Button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: "LogInForm",
  validate
})(LogInForm);