import React from 'react';
import styles from './news-search-result.module.css';

const NewsSearchResult = props => {
  return(
    <div className={ styles.searchResult } >
      <h2>
        <img src={ `https://www.google.com/s2/favicons?sz=20&domain_url=${props.value.url}` } alt="favicon" />
        <a href={ props.value.url }>{ props.value.name }</a>
      </h2>
      <p className={ styles.url }>{ props.value.url }</p>
      {
        props.value.image ?
          <div className={ styles.descWrap }>
            <div className={ styles.img } style={{ backgroundImage: `url(${props.value.image.thumbnail.contentUrl}&w=160)` }} alt={ props.value.name }></div>
            <p className={ styles.snippet }>{ props.value.description }</p>
          </div>
        : null
      }
      {
        !props.value.image ?
          <p>{ props.value.description }</p>
        : null
      }
    </div>
  );
};

export default NewsSearchResult;