import React from 'react';
import styles from './pagination.module.css';
import { Link, withRouter } from "react-router-dom";

const Pagination = props => {
  const maxPagesToShow = 9;
  const dummyArray = new Array(maxPagesToShow).fill();
  return(
    <div className={ styles.pagination }>
      {
        dummyArray.map((item, index) => {
          const pageNumber = props.current - ~~(maxPagesToShow / 2) + index;
          return(
            pageNumber > 0 ?
              <Link
                key={ index }
                className={ pageNumber === +props.current ? styles.current : "" }
                to={ `${props.history.location.pathname}?q=${encodeURIComponent(props.searchTerm)}&page=${pageNumber}` }
                onClick={ () => window.scrollTo(0,0) }
              >
                { pageNumber }
              </Link> 
            : null
          )
        })
      }
    </div>
  );
};

export default withRouter(Pagination);