import React from 'react';
import TextField from '@material-ui/core/TextField';

const renderTextField = (
  { input, label, startAdornment, meta: { touched, error }, ...custom }
) => (
  <TextField
    label={ label }
    error={ touched && !!error }
    helperText={ touched && error ? error : null }
    fullWidth
    autoComplete="off"
    { ...input }
    { ...custom }
  />
);

export default renderTextField;