import React from 'react';
import './registration.css';
import { withRouter, Link } from "react-router-dom";
import RegistrationForm from '../../components/registration-form/registration-form';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Introduction from '../../components/introduction/introduction';
import TermsAndCondition from '../../components/terms-and-condition/terms-and-condition';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmEmail from '../../components/confirm-email/confirm-email';
import ConfirmEmailSuccess from '../../components/confirm-email-success/confirm-email-success';
import RegistrationFinal from '../../components/registration-final/registration-final';
import SearchResultsHeader from '../../components/search-results-header/search-results-header';

const _introduction = "Introduction";
const _termsAndCondition = "Terms & Conditions";
const _personalInformation = "Personal Information";
const _emailConfirmation = "EmailConfirmation";
const _emailConfirmationSuccess = "EmailConfirmationSuccess";
const _final = "_final";

// sign up steps are renderes in order as they apear in this array
const registrationSteps = [
  _introduction,
  _personalInformation,
  _termsAndCondition,
  _emailConfirmation,
  _emailConfirmationSuccess,
  _final
];

class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      registrationError: "",
      ipCheck: { isValid: false, isChecked: false, countryCode: '' },
      invitedByCode: null,
      isLoding: true,
      newUserId: null,
      newUserInviteCode: null,
      affiliate: null,
      emailResent: false
    };
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);

    if(urlParams.get("step") === _emailConfirmation && urlParams.get("userId") && urlParams.get("confirmationCode")) {
      fetch("/api/confirm-email", {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          UserId: urlParams.get("userId"),
          ConfirmationCode: urlParams.get("confirmationCode")
        })
      })
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({
            currentStep: registrationSteps.findIndex(step => step === _emailConfirmation) + 1,
            isLoding: false,
            newUserId: urlParams.get("userId"),
            newUserInviteCode: urlParams.get("invitecode")
          });
        } else {
          this.props.history.push(`/error?message=${result.error}`);
        }
      });
    } else {
      fetch("/api/ip-check")
      .then(response => response.json())
      .then(result => {
        this.setState({
          isLoding: false,
          ipCheck: {
            isValid: result.success,
            isChecked: true,
            countryCode: result.countryCode
          }
        });

        if(!result.success) {
          this.props.history.push(`/error?message=${result.error}`);
        }
      });
    }

    const affiliate = urlParams.get("aff");
    if(affiliate) {
      this.setState({ affiliate: affiliate });
    }
    const inviteCode = urlParams.get("invitecode");
    if(inviteCode) {
      this.setState({ invitedByCode: inviteCode });
    }
  }

  nextStep() {
    this.setState({ currentStep: this.state.currentStep + 1 });
  }

  registerUser(values) {
    console.log("registering");
    this.setState({
      isLoding: true
    });

    fetch("/api/register-user", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        FirstName: values.FirstName,
        LastName: values.LastName,
        Month: values.Month,
        Year: values.Year,
        Email: values.Email,
        Password: values.Password,
        City: values.City,
        State: values.State,
        InviteCode: values.InviteCode,
        MobilePhoneNumber: values.MobilePhoneNumber,
        //ReCaptcha: values.ReCaptcha,
        AverageSearches: values.AverageSearches,
        Affiliate: this.state.affiliate
      })
    })
      .then(response => response.json())
      .then(result => {
        if(result.success) {
          console.log(result);
          this.setState({
            isLoding: false,
            newUserId: result.newUserId
          });
          setTimeout(() => {
            this.setState({
              currentStep: this.state.currentStep + 1
            });
          }, 400);
        } else {
          this.setState({
            isLoding: false,
            registrationError: result.error
          });
        }
      });
  }

  recordAcceptedTerms() {
    fetch("/api/record-accepted-terms", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        UserId: this.state.newUserId
      })
    });

    this.nextStep();
  }

  resendEmail() {
    this.setState({ emailResent: true });

    fetch("/api/resend-confirmation-email", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        UserId: this.state.newUserId
      })
    });
  }

  render() {
    return (
      <div className="registration">
        <Backdrop className="on-registration-back-drop" open={this.state.isLoding}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <SearchResultsHeader
          handleSearch={ data => this.props.history.push(`/search?q=${data.q}&page=1`) }
          mainSearchStyle={{transform: "translateX(-50%)",  left: "50%", maxWidth: "600px" }}
          user={ this.props.user }
          hideTabs={ false }
        />
        <div className="registration-form-wrap">
          <SwitchTransition>
            <CSSTransition
              key={ this.state.currentStep }
              addEndListener={ (node, done) => node.addEventListener("transitionend", done, false) }
              classNames="transitionRight"
            >
              {
                this.state.currentStep === registrationSteps.findIndex(step => step === _introduction) ?
                  <Introduction onContinue={ () => this.nextStep() } countryCode={this.state.ipCheck.countryCode} />
                : this.state.currentStep === registrationSteps.findIndex(step => step === _termsAndCondition) ?
                  <TermsAndCondition onContinue={ () => this.recordAcceptedTerms() } />
                : this.state.currentStep === registrationSteps.findIndex(step => step === _personalInformation) ?
                  <RegistrationForm
                    handleRegistration={ values => this.registerUser(values) }
                    errorMessage={ this.state.registrationError }
                    invitedByCode={ this.state.invitedByCode }
                    countryCode={ this.state.ipCheck.countryCode }
                  />
                : this.state.currentStep === registrationSteps.findIndex(step => step === _emailConfirmation) ?
                  <ConfirmEmail resendEmail={ () => this.resendEmail() } emailResent={ this.state.emailResent } />
                : this.state.currentStep === registrationSteps.findIndex(step => step === _emailConfirmationSuccess) ?
                  <ConfirmEmailSuccess onNext={ () => this.nextStep() } />
                : this.state.currentStep === registrationSteps.findIndex(step => step === _final) ?
                  <RegistrationFinal userId={ this.state.newUserId } inviteCode={ this.state.newUserInviteCode } />
                : null
              }
            </CSSTransition>
          </SwitchTransition>
          
          <div className="register-log-in-wrap">
            <Link className={ `register-log-in-link ${this.state.currentStep === 2 ? "bigger-form" : "" }` } to="/log-in">Already have an account? Log in!</Link>
          </div>
          
          <div className="stepper-wrap">
            <Stepper alternativeLabel activeStep={ this.state.currentStep } >
              <Step key={ registrationSteps.findIndex(step => step === _introduction) }>
                <StepLabel>{ _introduction }</StepLabel>
              </Step>
              <Step key={ registrationSteps.findIndex(step => step === _personalInformation) }>
                <StepLabel>{ _personalInformation }</StepLabel>
              </Step>
              <Step key={ registrationSteps.findIndex(step => step === _termsAndCondition) }>
                <StepLabel>{ _termsAndCondition }</StepLabel>
              </Step>
              <Step key={ registrationSteps.findIndex(step => step === _emailConfirmation) }>
                <StepLabel>Verification</StepLabel>
              </Step>
            </Stepper>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Registration);
