import React from 'react';
import { withRouter } from "react-router-dom";
import styles from './auth-buttons-group.module.css';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Avatar from '@material-ui/core/Avatar';

const _userSeassionIdentifier = "_user";

const AuthButtonsGroup = props => {
  const [open, setOpen] = React.useState(false);
  const [avatarEl, setAvatarEl] = React.useState();

  const openMenu = e => {
    setOpen(true);
    setAvatarEl(e.currentTarget);
  };
  
  return(
    <div className={ styles.authWrap }>
      {
        !props.user ?
          <>
            <Button className={props.color === "black" ? styles.button : undefined} variant="outlined" onClick={ () => props.history.push("/registration") }>Register</Button>
            <Button className={props.color === "black" ? styles.button : undefined} variant="outlined" onClick={ () => props.history.push("/log-in") }>Sign in</Button>
          </>
        :
          <>
            <Avatar onClick={ openMenu }>{ props.user.firstName[0] + props.user.lastName[0] }</Avatar>
            <Menu
              id="fade-menu"
              anchorEl={avatarEl}
              open={open}
              onClose={ () => setOpen(false) }
              TransitionComponent={Fade}
            >
              <MenuItem onClick={ () => props.history.push("/profile") }>Profile</MenuItem>
              <MenuItem onClick={ () => props.history.push("/my-account") }>My account</MenuItem>
              <MenuItem onClick={ () => {
                sessionStorage.removeItem(_userSeassionIdentifier);
                window.location.replace("/log-out");
              }}>
                Logout
              </MenuItem>
            </Menu>
          </>
      }
    </div>
  );
};

export default withRouter(AuthButtonsGroup);