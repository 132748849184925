import React from 'react';
import styles from './search-result.module.css';

const SearchResult = props => {
  const handleEnterKey = event => {
    if (event.keyCode === 13) {
      window.location.assign(props.value.url);
    }
  };

  React.useEffect(() => {
    if (props.selected) {
      document.addEventListener('keydown', handleEnterKey);
    } else {
      document.removeEventListener('keydown', handleEnterKey);
    }

    return () => {
      if (props.selected) {
        document.removeEventListener('keydown', handleEnterKey);
      }
    };
    // eslint-disable-next-line
  }, [props.selected]);

  return(
    <div ref={props.frwdRef} className={ `${styles.searchResult} ${props.selected ? styles.selected : ''}` } >
      <h2>
        <a href={ props.value.url }>{ props.value.name }</a>
      </h2>
      <p className={ styles.url }>
        <img src={ `https://www.google.com/s2/favicons?sz=24&domain_url=${props.value.url}` } alt="favicon" />
        { props.value.displayUrl }
      </p>
      <p className={ styles.snippet }>{ props.value.snippet }</p>
      {
        props.value.deepLinks && props.value.deepLinks.length > 0 ?
          <div className={ styles.deepLinksWrap }>
            {
              props.value.deepLinks.slice(0, 4).map(deepLink => {
                return(
                  <div className={ styles.deepLink } key={ deepLink.url } >
                    <h3><a href={ deepLink.url }>{ deepLink.name }</a></h3>
                    <p className={ styles.snippet }>{ deepLink.snippet }</p>
                  </div>
                )
              })
            }
          </div>
        : null
      }
    </div>
  );
};

export default SearchResult;