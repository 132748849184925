import React from 'react';
import styles from './registration-form.module.css';
import { Field, reduxForm, Form } from 'redux-form'
import renderTextField from '../material-ui/TextField';
//import renderSelectField from '../material-ui/Select';
import Button from '@material-ui/core/Button';
import RectangularShadowWrap from '../rectangular-shadow-wrap/rectangular-shadow-wrap';
import InputLabel from '@material-ui/core/InputLabel';
//import { DAYS_IN_MONTH, US_STATES } from '../../const';
//import { yearsRangeDateOfBirth } from '../../config';
import { formValidations } from './form-validations';
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

class RegistrationForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentYear: new Date().getFullYear(),
      oneToTwo: false,
      threeToFive: false,
      sixToNine: false,
      tenPlus: false,
      formValues: {},
      formInitialized: false
    }
  }

  componentDidUpdate() {
    if(!this.state.formInitialized && this.props.invitedByCode) {
      this.props.change("InviteCode", this.props.invitedByCode);
      this.setState({ formInitialized: true });
    }
  }

  normalizePhone(value) {
    if (!value) {
      return value
    }
  
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
      return onlyNums
    }
    if (onlyNums.length <= 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6,10)}`
  }

  render() {
    return(
      <RectangularShadowWrap style={{ maxWidth: "700px" }}>
        <h2 className={ styles.title }>Uzvy Sign Up: User Data</h2>
        <Divider variant="middle" />
        <Form className={ styles.form } noValidate onSubmit={
          this.props.handleSubmit(values => {
            const newFormValues = { ...values, AverageSearches: this.state.oneToTwo ? "1 - 2" : this.state.threeToFive ? "3 - 5" : this.state.sixToNine ? "6 - 9" : this.state.tenPlus ? "10+" : null };

            this.props.handleRegistration(newFormValues);
          })
        }>
          <div className={ styles.formPage }>
            <div className={ styles.fieldWrap }>
              <Field
                name="FirstName"
                label="First & Middle Name"
                component={ renderTextField }
                required
              />
            </div>
            <div className={ styles.fieldWrap }>
              <Field
                name="LastName"
                label="Last Name"
                component={ renderTextField }
                required
              />
            </div>
            {/*<div className={ styles.fieldWrap }>
              <Field
                name="City"
                label="City"
                component={ renderTextField }
                required
              />
            </div>
            <div className={ styles.fieldWrap }>
              <Field
                name="State"
                id="State"
                label="State"
                options={ US_STATES.reduce(
                  (acc, value) => {
                    acc.push({ value: value, label: value });
                    return acc;
                  },
                []) }
                component={ renderSelectField }
                required
              />
            </div>*/}
            <div className={ styles.fieldWrap }>
              <Field
                name="Email"
                label="Email"
                component={ renderTextField }
                required
              />
            </div>
            {/*<div className={ styles.fieldWrap }>
              <InputLabel>Birth Month | Year (you must be 18 or over to join Uzvy)</InputLabel>
              <div className={ styles.month }>
                <Field
                  name="Month"
                  id="Month"
                  label="Month"
                  options={ DAYS_IN_MONTH.reduce(
                    (acc, value) => {
                      acc.push({ value: value.monthNo, label: value.month });
                      return acc;
                    },
                  []) }
                  component={ renderSelectField }
                  required
                />
              </div>
              <div className={ styles.year }>
                <Field
                  name="Year"
                  id="Year"
                  label="Year"
                  options={ new Array(yearsRangeDateOfBirth).fill().reduce(
                    (acc, value, index) => {
                      const startYear = this.state.currentYear - 18; // -18 goes to adjust for the minimum age
                      acc.push({ value: startYear - index, label: startYear - index });
                      return acc;
                    },
                  []) }
                  component={ renderSelectField }
                  required
                />
              </div>
            </div>*/}
            <div className={ styles.fieldWrap }>
              <Field
                name="InviteCode"
                label="Invite code (optional)"
                component={ renderTextField }
              />
            </div>
          </div>
          <div className={ styles.formPage }>
            {
              this.props.countryCode === 'US' ?
                <div className={ styles.fieldWrap }>
                  <Field
                    name="MobilePhoneNumber"
                    label="Mobile phone number"
                    normalize={ value => this.normalizePhone(value) }
                    component={ renderTextField }
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+1</InputAdornment>
                    }}
                    required
                  />
                </div>
                : null
            }
            <div className={ styles.fieldWrap }>
              <Field
                name="Password"
                label="Password"
                type="password"
                component={ renderTextField }
                required
              />
            </div>
            <div className={ styles.fieldWrap }>
              <Field
                name="RepeatPassword"
                label="Confirm password"
                type="password"
                component={ renderTextField }
                required
              />
            </div>
            <div className={ styles.fieldWrap }>
              <InputLabel>How many searches on average do you do across your tablet, desktop, laptop, and phone in a day:</InputLabel>
              <FormGroup row>
                <FormControlLabel
                  control={ <Checkbox
                    checked={this.state.oneToTwo}
                    onChange={ () => {
                      this.setState({
                        oneToTwo: !this.state.oneToTwo,
                        threeToFive: false,
                        sixToNine: false,
                        tenPlus: false
                      })
                    }}
                    name="oneToTwo"
                    color="primary"
                  /> }
                  label="1 - 2"
                />
                <FormControlLabel
                  control={ <Checkbox
                    checked={this.state.threeToFive}
                    onChange={ () => {
                      this.setState({
                        oneToTwo: false,
                        threeToFive: !this.state.threeToFive,
                        sixToNine: false,
                        tenPlus: false
                      })
                    }}
                    name="threeToFive"
                    color="primary"
                  /> }
                  label="3 - 5"
                />
                <FormControlLabel
                  control={ <Checkbox
                    checked={this.state.sixToNine}
                    onChange={ () => {
                      this.setState({
                        oneToTwo: false,
                        threeToFive: false,
                        sixToNine: !this.state.sixToNine,
                        tenPlus: false
                      })
                    }}
                    name="sixToNine"
                    color="primary"
                  /> }
                  label="6 - 9"
                />
                <FormControlLabel
                  control={ <Checkbox
                    checked={this.state.tenPlus}
                    onChange={ () => {
                      this.setState({
                        oneToTwo: false,
                        threeToFive: false,
                        sixToNine: false,
                        tenPlus: !this.state.tenPlus
                      })
                    }}
                    name="tenPlus"
                    color="primary"
                  /> }
                  label="10+"
                />
              </FormGroup>
            </div>
          </div>
          {
            !!this.props.errorMessage ?
              <Alert className={ styles.alert } variant="outlined" severity="error">
                { this.props.errorMessage }
              </Alert>
            : null
          }
          <div className={ styles.buttonWrap }>
            <Button variant="contained" color="primary" type="submit">Next</Button>
          </div>
        </Form>
      </RectangularShadowWrap>
    );
  }
};

export default reduxForm({
  form: "RegistrationForm",
  validate: formValidations
})(RegistrationForm);