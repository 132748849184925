import React from 'react';
import styles from './header.module.css';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { withRouter } from 'react-router-dom';
import AppMenu from '../../components/menu/menu';
import AuthButtonsGroup from '../auth-buttons-group/auth-buttons-group';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../assets/logotwo.png';

const _userSeassionIdentifier = "_user";

const Header = props => {
  const [open, setOpen] = React.useState(false);
  const [openAppMenu, setOpenAppMenu] = React.useState(false);
  const [avatarEl] = React.useState();

  return(
    <div className={ `${styles.headerWrap} ${props.className ? props.className : ""} ${props.size === 'small' ? styles.small : ''}` }>
      {
        props.showLogo ?
        <div className={styles.logo}>
          <img src={logo} alt="Logo" />
        </div>
        : null
      }
      <div className={ styles.avatar }>
        <div className={styles.buttonsWrap}>
          <AuthButtonsGroup user={props.user} color="black" />
          <Button onClick={() => setOpenAppMenu(value => !value)}><MenuIcon /></Button>
        </div>
        <Menu
          id="fade-menu"
          anchorEl={avatarEl}
          open={open}
          onClose={ () => setOpen(false) }
          TransitionComponent={Fade}
        >
          {
            !!props.user ?
              <MenuItem onClick={ () => props.history.push("/profile") }>Profile</MenuItem>
            : null
          }
          {
            !!props.user ?
              <MenuItem onClick={ () => props.history.push("/my-account") }>My account</MenuItem>
            :
              <MenuItem onClick={ () => props.history.push("/log-in") }>Log in</MenuItem>
          }
          {
            !!props.user ?
              <MenuItem onClick={ () => {
                sessionStorage.removeItem(_userSeassionIdentifier);
                window.location.replace("/log-out");
              }}>
                Logout
              </MenuItem>
            :
              <MenuItem onClick={ () => props.history.push("/registration") }>Register</MenuItem>
          }
        </Menu>
      </div>
      { props.children }

      <AppMenu open={openAppMenu} onClose={() => setOpenAppMenu(false)} />
    </div>
  );
};

export default withRouter(Header);