import React from 'react';
import './winners.css';
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import SearchResultsHeader from '../../components/search-results-header/search-results-header';
import { Link, withRouter } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable from 'material-table';

class Winners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      prizes: null
    };
  }

  render() {
    return (
      <div className="winners-wrap">
        <SearchResultsHeader
          handleSearch={ data => this.props.history.push(`/search?q=${data.q}&page=1`) }
          mainSearchStyle={{ transform: "translateX(-50%)",  left: "50%", maxWidth: "600px" }}
          user={ this.props.user }
          hideTabs={ false }
        />

        {
          this.state.isLoading ? 
            <Backdrop className="on-registration-back-drop" open={this.state.isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          :<>  
            <div className="winners-element">
              <Card>
                <CardHeader color="primary">
                  <h2>Uzvy Winners</h2>
                  <h3>Follow us on Twitter at <Link href="https://twitter.com/uzvywinners" target="_blank">twitter.com/uzvywinners</Link></h3>
                </CardHeader>
                <CardBody>
                  <MaterialTable
                    columns={[
                      { title: "Name", field: "Name", sorting: false },
                      { title: "City", field: "City", sorting: false },
                      { title: "State", field: "State", sorting: false },
                      { title: "Prize", field: "PrizeName", sorting: false },
                      { title: "Date", field: "Date", sorting: false, type:"date" }
                    ]}
                    title="Winners"
                    options={{
                      pageSize: 5,
                      pageSizeOptions: [ 5, 10, 15 ],
                      search: false,
                      rowStyle: {
                        fontFamily: "Roboto"
                      }
                    }}
                    data={query =>
                      new Promise((resolve, reject) => {
                        fetch(`/api/get-winners?page=${+query.page}&pageSize=${query.pageSize}`)
                        .then(response => response.json())
                        .then(result => {
                          resolve({
                            data: result.data,
                            totalCount: result.totalCount,
                            page: result.page
                          });
                        })
                        .catch(() => reject(false));
                    })}
                    components={{
                      Container: props => <Paper {...props} elevation={0}/>
                    }}
                  />
                </CardBody>
              </Card>
            </div>
          </>
        }
        
      </div>
    );
  }
}

export default withRouter(Winners);
