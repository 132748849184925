import React from 'react';
import './home.css';
import { withRouter } from "react-router-dom";
import MainSearch from '../../components/main-search/main-search';
import AuthButtonsGroup from '../../components/auth-buttons-group/auth-buttons-group';
import logo from '../../assets/uzvy-logo.png';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { Element, scroller } from 'react-scroll';
import { ReactComponent as AppStoreIcon } from '../../assets/app-store-icon.svg';
import { ReactComponent as ChromeIcon } from '../../assets/chrome-icon.svg';
import { ReactComponent as AndroidIcon } from '../../assets/android-icon.svg';
import Link from '@material-ui/core/Link';
import winnersImage from '../../assets/winners_border.png';
import Divider from '@material-ui/core/Divider';
import nature from '../../assets/naturey_border.png';
import choose from '../../assets/forests.jpg';
import sun from '../../assets/sun.png';
import line from '../../assets/line.png';
import Button from '@material-ui/core/Button';
import CountUp from 'react-countup';
import MenuIcon from '@material-ui/icons/Menu';
import microsoftBingLogo from '../../assets/microsoft_bing.png';
import Footer from '../../components/footer/footer';
import Menu from '../../components/menu/menu';
import Platform from 'platform';
import secure from '../../assets/secure.png';
import explode from '../../assets/explode.png';

const _scrollOptions = {
  duration: 700,
  delay: 50,
  smooth: true,
  offset: -40
};

class Home extends React.Component {
  startRef;

  constructor(props) {
    super(props);
    this.state = {
      startSectionHeight: 0,
      openMenu: false
    };
    this.startRef = React.createRef();
  }

  componentDidMount() {
    this.setState({startSectionHeight: this.startRef.current.clientHeight});
  }

  render() {
    return (
      <>
        {
          new URLSearchParams(this.props.location.search).get("d")
          || new URLSearchParams(this.props.location.search).get("f")
          || new URLSearchParams(this.props.location.search).get("o")
          || new URLSearchParams(this.props.location.search).get("a")
          || new URLSearchParams(this.props.location.search).get("b")
          || new URLSearchParams(this.props.location.search).get("i") ?
            <>
              <div className="info-bar fixed">
                {
                  Platform.os.family.toLowerCase().includes('android') || Platform.os.family.toLowerCase().includes('ios') ?
                    <p>Get the Uzvy App</p>
                    : Platform.name.toLowerCase().includes('chrome') ?
                      <p>Don't miss a prize entry.  Save our planet everyday.  Make Uzvy your default search engine in Chrome</p>
                      : <p>Don't miss a prize entry.  Save our planet everyday. Make Uzvy your default search engine</p>
                }
              </div>
              <div className="info-bar scrolling-bar">
                <p>Uzvy is a new search engine that gives over half our revenues each month to save the planet & to daily prizes for you!</p>
              </div>
            </>
            : null
        }
        <div className="home-wrap">
          <section ref={this.startRef} className="start-section" style={{minHeight: `${this.state.startSectionHeight}px`}}>
            <div className="homepage-auth-buttons">
              <AuthButtonsGroup user={ this.props.user } />
              <Button onClick={() => this.setState({openMenu: !this.state.openMenu})}><MenuIcon /></Button>
            </div>
            <div className="search-wrap">  
              <img src={ logo } alt="Uzvy Search Logo" />
              
              <MainSearch handleSearch={data => {
                if (this.props.match.params.id && data.q) {
                  this.props.history.push(`/${this.props.match.params.id}/search${this.props.location.search ? this.props.location.search + '&' : '?'}q=${encodeURIComponent(data.q)}&page=1`);
                } else if (data.q) {
                  this.props.history.push(`/search${this.props.location.search ? this.props.location.search + '&' : '?'}q=${encodeURIComponent(data.q)}&page=1`);
                }
              }} />
              <div className="resultsFromMSBing">
                <img className="resultsFromMSBingImg" src={microsoftBingLogo} alt="Results from Miscrosoft Bing" /> 
              </div>           
            </div>

            <div className="start-text-centered">
              <p>Save 1 Tree and Countless Wildlife Today*</p>
              <p><b><CountUp end={this.props.stats.treesSaved} duration={2} separator="," /></b> acres of forest saved by Uzvy users to date</p>
              <p><b><CountUp key={this.props.stats.co2Saved} end={this.props.stats.co2Saved} duration={2} separator="," /></b> Tons of CO2 saved</p>
              <p><b><CountUp key={this.props.stats.winners} end={this.props.stats.winners} duration={2} separator="," /></b> Prizes Awarded</p>

            </div>

            <div className="start-text-bottom">
              <p>Today's Cause: {this.props.cause.name}</p>
              <p>Today's Prize: {this.props.prize.name}</p>

              <div
                className="learn-more"
                onClick={ () => scroller.scrollTo("about", _scrollOptions) }
              >
                <p>Learn More</p>
                <ExpandMoreOutlinedIcon />
              </div>
            </div>
          </section>
          <Element className="about-section" name="about">
            <p><h2>Welcome to Uzvy, your new search engine.</h2></p>
            <div className="about-content">
                <p className="power">Save forests<br />Save wildlife<br /> Save our planet </p>

              <div>
                <p>Make a Difference Every Day.  Uzvy distributes over 50% of our revenues to save the planet and to prizes like free Teslas (coming soon!) for you.</p> 
                <p>You'll love our world class search results, and in just one month on average, you'll offset your entire, annual, carbon footprint.  You'll have a chance to win prizes from Amazon Gift cards to eventually a new Tesla.</p>
                <p>To win daily prizes when you search,  <Link href="https://www.uzvy.com/registration" target="_blank">join Uzvy</Link><br /><span className="small">(You must be a legal U.S. resident 18 or older. More countries will be supported soon.</span></p>
              </div>
            </div>
          <img src={ line } alt="forest" className="responsive" /> 
          </Element>
                      
          <Element className="more-ways-section" name="more-ways">
            <h2>More ways to use Uzvy</h2>
            <div className="devices-container">
              <div className="device-element">
                <AppStoreIcon />
                <p>iPhone/iPad</p>
              </div>
              <div className="device-element">
                <AndroidIcon />
                <p>Android</p>
              </div>
              <div className="device-element chrome" onClick={() => window.open('https://epic.b-cdn.net/uzvy/uzvy_new_tab.zip', '_blank')}>
                <ChromeIcon />
                <p>Chrome Extension</p>
              </div>
              <div className="device-element">
                <Link>Set Uzvy as default search in your browser</Link>
              </div>
            </div>
          </Element>
          <Divider />
          
          <Element className="privacy-section" name="privacy">
          <h2>How Uzvy Works</h2>
            <div className="privacy-element-container">
              <div className="privacy-element">
                <div className="privacy-element-image" style={{ backgroundImage: `url(${secure})` }} />
                <div>
                  <p><b>PRIVACY.  WE DON'T USE TRACKERS OR SELL YOUR DATA.</b></p>
                  <p>We don't market anything to you or sell your personal data. There's nothing to buy.</p>
                   <p>We're transparent about how Uzvy works. When you search with Uzvy, we support causes and reward you, Uzvy users, with fantastic prizes
                  which get bigger as more folks use Uzvy.</p>  
                  <p>See the current and past <Link href="https://www.uzvy.com/causes" target="_blank">causes</Link>, <Link href="https://www.uzvy.com/prizes" target="_blank">prizes</Link>, <Link href="https://www.uzvy.com/winners" target="_blank">winners</Link> and <Link href="https://www.finesage.com/rules.html" target="_blank">Official Rules</Link>.</p>
                </div>
              </div>
              <div className="privacy-element">
                <div className="privacy-element-image" style={{ backgroundImage: `url(${winnersImage})` }} />
                <div>
                  <p><b>WIN DAILY PRIZES.</b></p>
                  <p>Prizes get bigger as we grow so invite your friends.  We're at "prize level one" with a daily $100 Amazon gift card.  We'll soon give away more gift cards of higher value, then a new Tesla every month and with enough users a new Tesla every day.</p> 
                  <p>After you join Uzvy, you get one daily prize entry for each search you make (up to 5 a day), a bonus 5 or 10 entries if you've maintained a 10 or 30 day streak, and an entry for each search your friends or family make (up to 5 per person and 20 total per day).  Each day you use Uzvy as your default search engine in your desktop and mobile browsers, your prize entries are doubled. </p>
                </div>
              </div>
              <div className="privacy-element">
                <div className="privacy-element-image" style={{ backgroundImage: `url(${nature})` }} />
                <div>
                  <p><b>PROTECT WILDLIFE & FORESTS.</b></p><p><b>OFFSET YOUR CARBON FOOTPRINT 12 TIMES OVER.</b></p>
                  <p>Every search with Uzvy helps save a forest, protect wildlife, and reduce CO2 emissions.  Offset your entire annual CO2 footprint every month on average when you use Uzvy as your primary search engine. </p>
                  <p>We give a large portion of our revenues every month to organizations to purchase and preserve forests around the world.  You can find more details about forests you've preserved, species you've protected and our other initiatives on our <Link href="https://www.uzvy.com/causes" target="_blank">causes page</Link>.</p>
                </div>
              </div>
            </div>
          </Element>
          
      <img src={ choose } alt="Choose Forest or Deforestation" className="responsive"/>  
          
          <Element className="how-uzvy-works-section" name="how-uzvy-works">       
               <div className="how-uzvy-works-container">
              
              <h3><img src={ explode } alt="impact" height="40px" />  Your Impact  <img src={ explode } alt="impact" height="40px" /></h3>
   
              <p>As a single Uzvy user, you have an enormous impact.  <span className="highlight">In just one year of using Uzvy, you'll save nearly an acre of wild forest (1+ trees every day) on average, protect uncountable wildlife, 
              and keep 12x your annual footprint of CO2 sequestered in the forest.</span>  For countries in which we earn higher revenues such as the U.S., one person using Uzvy will save nearly 3 acres of forest annually (2+ trees every day).</p>

              
              <h3><img src={ sun } alt="sun" height="40px" />  Supported Countries  <img src={ sun } alt="sun" height="40px" /></h3>
                         
              <p>Search as you normally do on Uzvy.  Please don't perform unnecessary searches or click on links randomly -- that actually hurts our efforts.</p>  
              <p>Anyone in the world can and should search with Uzvy, but only searches from the U.S., Canada, the U.K., Germany, and France currently contribute towards causes because we don't earn revenues in other countries yet (more are coming).</p>
              <p>Due to different laws in different countries, only legal, current residents of the U.S. 18 years or older are eligible for prizes. We will support more countries soon. 
              For a chance to win Daily Prizes when you search, <b> <Link href="https://www.uzvy.com/registration" target="_blank">Join Uzvy</Link></b>. It's free and takes less than a minute. We never sell your data, and there's absolutely nothing
              to purchase to gain entries or win a prize. You must have an account and either be logged in or use your assigned url to gain prize entries through Uzvy search.  Please see the <Link href="https://www.finesage.com/rules.html" target="_blank">Official Rules</Link> for full eligibility requirements and other details.</p>         
              
            </div>
          </Element>
          <Element className="stripe-section" name="stripe">
            <h2><span class="highlight">Save our planet. Get great search results.</span></h2>
          </Element>
          <img src={ line } alt="forest" class="responsive" /> 
         <Footer />
        </div>

        <Menu open={this.state.openMenu} onClose={() => this.setState({openMenu: false})} />
      </>
    );
  }
}

export default withRouter(Home);