import React from 'react';
import { withRouter } from "react-router-dom";
import Pagination from '../../components/pagination/pagination';
import SearchResultsHeader from '../../components/search-results-header/search-results-header';
import Link from '@material-ui/core/Link';
import VideoSearchResultSkeleton from '../../components/video-search-result-skeleton/video-search-result-skeleton';
import NewsSearchResult from '../../components/news-search-result/news-search-result';
import SearchSettings from '../../components/search-settings/search-settings';
import { storage } from '../../helpers';

const _freshnessSessionId = "_freshness";
const _safesearchSessionId = "_safesearch";
const _regionSessionId = "_region";
const _rememberSettingsSessionId = "_rememberSettings";

const _storage = storage;

class NewsResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
      page: 1,
      searchResults: {},
      isSearching: false,
      isLoading: true,
      showSettings: false,
      freshness: "All",
      safesearch: "Moderate",
      region: "en-US",
      rememberSettings: false
    };
  }

  componentDidMount() {
    this.setState({
      freshness: _storage.getItem(_freshnessSessionId) || "All",
      safesearch: _storage.getItem(_safesearchSessionId) || "Moderate",
      region: _storage.getItem(_regionSessionId) || "en-US",
      rememberSettings: window.localStorage.getItem(_rememberSettingsSessionId) === "true"
    }, () => this.getSearchResults());
  }

  addSearchEvent(userId) {
    fetch("/api/add-search-event", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        userId: userId,
        searchTerm: this.state.searchTerm
      })
    })
  }

  getSearchResults() {
    const params = new URLSearchParams(this.props.location.search);
    const q = params.get("q");
    const page = params.get("page") ? params.get("page") : 1;

    fetch(`/api/search-news?q=${encodeURIComponent(q)}&page=${page}&freshness=${this.state.freshness}&safesearch=${this.state.safesearch}&region=${this.state.region}`,{
      headers:{
        accept: 'application/json',
        'User-agent': 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      }})
      .then(response => response.json())
      .then(result => {
        this.setState({
          searchTerm: q,
          page: page,
          searchResults: result.data,
          isSearching: false,
          isLoading: false
        }, () => {
          if (!!this.props.match.params.id && +page === 1) {
            this.addSearchEvent(this.props.match.params.id);
          } else if (!!this.props.user && +page === 1) {
            this.addSearchEvent(this.props.user.id);
          }
        });
      })
      .catch(() => this.props.history.push("/error?message=There was an unknown error. Please try again later."));
  }


render() {
    return (
      <>
        <SearchResultsHeader
          isSearching={ this.state.isSearching }
          searchTerm={ this.state.searchTerm }
          user={ this.props.user }
          explicitUserId={ this.props.match.params.id }
          handleSettingsClick={ () => this.setState({ showSettings: !this.state.showSettings }) }
          settingsActive={ this.state.showSettings }
          menuType="search"
          handleSearch={ data => {
            this.setState({ isSearching: true });
            this.props.history.push(`${this.props.history.location.pathname}?q=${encodeURIComponent(data.q)}&page=1`);
          }}
        />
        <SearchSettings
          freshness={ this.state.freshness }
          handleFreshnessChange={ e => {
            this.setState({
              freshness: e.target.value, isLoading: true
            }, () => this.getSearchResults());
            _storage.setItem(_freshnessSessionId, e.target.value, this.state.rememberSettings);
          }}
          safesearch={ this.state.safesearch }
          handleSafesearchChange={ e => {
            this.setState({
              safesearch: e.target.value, isLoading: true
            }, () => this.getSearchResults());
            _storage.setItem(_safesearchSessionId, e.target.value, this.state.rememberSettings);
          }}
          region={ this.state.region }
          handleRegionChange={ e => {
            this.setState({
              region: e.target.value, isLoading: true
            }, () => this.getSearchResults());
            _storage.setItem(_regionSessionId, e.target.value, this.state.rememberSettings);
          }}
      rememberSettings={ this.state.rememberSettings }
          handleRememberSettingsChange={ () => {
            _storage.setItem(_freshnessSessionId, this.state.freshness, !this.state.rememberSettings);
            _storage.setItem(_safesearchSessionId, this.state.safesearch, !this.state.rememberSettings);
            _storage.setItem(_regionSessionId, this.state.region, !this.state.rememberSettings);
            window.localStorage.setItem(_rememberSettingsSessionId, !this.state.rememberSettings, !this.state.rememberSettings);
            this.setState({ rememberSettings: !this.state.rememberSettings });
          }}
          showSettings={ this.state.showSettings }
        />
        {
          this.state.isLoading ?
            <div className="search-results-bg">
              <div className="results-wrap">
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
                <VideoSearchResultSkeleton />
              </div>
            </div>
          : !this.state.searchResults?.value?.length > 0 ?
            <div className="no-search-results-wrap">
              <p>We didn't find any results for: "<b>{ this.state.searchTerm }</b>"</p>
              <p>Please check that all words are spelled correctly or try different keywords.</p>
            </div>
          :
            <div className="search-results-bg">
              <div className="results-wrap">
                {
                  this.state.searchResults?.value.map(result => <NewsSearchResult key={ result.url } value={ result } />)
                }

                <hr />
                <div className="search-results-end-wrap">
                  <p>{ this.state.searchResults.totalEstimatedMatches?.toLocaleString() } results</p>
                  <Link href="https://privacy.microsoft.com/en-US/privacystatement" target="_blank">Results by Microsoft</Link>
                </div>
                <div className="pagination-wrap">
                  <Pagination current={ this.state.page } searchTerm={ this.state.searchTerm } explicitUserId={ this.props.match.params.id } />
                </div>
              </div>
            </div>
        }
      </>
    );
  }
}

export default withRouter(NewsResults);


