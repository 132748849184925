import React from 'react';
import { withRouter, } from 'react-router-dom';
import Header from '../../components/header/header';
import RectangularShadowWrap from '../../components/rectangular-shadow-wrap/rectangular-shadow-wrap';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

class NewPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: { value: "", isTouched: false, error: "" },
      repeatPassword: { value: "", isTouched: false, error: "" },
      isLoading: false,
      success: false,
      errorMessage: "",
      email: "",
      code: ""
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.setState({
      email: params.get("email"),
      code: params.get("code"),
    });
  }

  setNewPassword() {
    this.setState({ isLoading: true, success: false, errorMessage: "" });
    fetch("/api/set-new-password", {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        Email: this.state.email,
        Code: this.state.code,
        Password: this.state.password.value
      })
    })
    .then(response => response.json())
    .then(result => {
      if (result.success) {
        this.setState({
          isLoading: false,
          success: true
        });
      } else {
        this.setState({
          isLoading: false,
          errorMessage: result.error
        });
      }
    })
    .catch(() => {
      this.setState({
        isLoading: false,
        errorMessage: "An error has occurred. Please try again or contact us."
      });
    });
  }

  render() {
    return (
      <div className="password-reset">
        <Header></Header>
        <div className="password-reset-form-container">
          <RectangularShadowWrap>
            <LockOutlinedIcon className="lock-icon" />
            <h2>Set a new password</h2>
            <div className="password-reset-form-wrap">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="Password"
                label="Password"
                type="password"
                id="Password"
                onChange={ e => {
                  if(!!e.target.value) {
                    this.setState({ password: { value: e.target.value, isTouched: true, error: "" }});
                    this.setState({ repeatPassword: { ...this.state.repeatPassword, error: "" }});
                  } else {
                    this.setState({ password: { value: e.target.value, isTouched: true, error: "Password is a requred field." }});
                  }
                }}
                onBlur={ e => {
                  if(this.state.repeatPassword.isTouched && this.state.password.value !== this.state.repeatPassword.value) {
                    this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "Passwords do not match." }});
                  }
                }}
                error={ !!this.state.password.error && this.state.password.isTouched }
                helperText={ !!this.state.password.error && this.state.password.isTouched ? this.state.password.error : null }
                autoComplete="new-password"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="RepeatPassword"
                label="Repeat password"
                type="password"
                id="RepeatPassword"
                onChange={ e => {
                  if(!!e.target.value) {
                    this.setState({ repeatPassword: { value: e.target.value, isTouched: true, error: "" }});
                  } else {
                    this.setState({ repeatPassword: { value: e.target.value, isTouched: true, error: "Repeat password is a requred field." }});
                  }
                }}
                onBlur={ e => {
                  if(this.state.repeatPassword.isTouched && this.state.password.value !== this.state.repeatPassword.value) {
                    this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "Passwords do not match." }});
                  } else if(this.state.repeatPassword.isTouched) {
                    if(!this.state.repeatPassword.value.match(/^(?=.*\d)(?=.*[a-z]).+$/)) {
                      this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "Password must include at least one number and one lower case letter." }});
                    } else if(this.state.repeatPassword.value.length < 6) {
                      this.setState({ repeatPassword: { ...this.state.epeatPassword, isTouched: true, error: "Password must have at least 6 characters." }});
                    } else {
                      this.setState({ repeatPassword: { ...this.state.repeatPassword, isTouched: true, error: "" }});
                    }
                  }
                }}
                error={ !!this.state.repeatPassword.error && this.state.repeatPassword.isTouched }
                helperText={ !!this.state.repeatPassword.error && this.state.repeatPassword.isTouched ? this.state.repeatPassword.error : null }
                autoComplete="new-password"
              />

              {
                !!this.state.errorMessage ?
                  <Alert className="alert" variant="outlined" severity="error">
                    { this.state.errorMessage }
                  </Alert>
                : null
              }

              {
                !!this.state.success ?
                  <Alert className="alert" variant="outlined">
                    You have successfully set a new password.
                  </Alert>
                : null
              }

              {
                this.state.success ?
                  <Button
                    className="password-reset-button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={ () => {
                      this.props.history.push("/log-in");
                    }}
                  >
                    Log in { this.state.isLoading ? <>&nbsp;&nbsp;<CircularProgress color="inherit" size={18} /></> : null }
                  </Button>
                :
                  <Button
                    className="password-reset-button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={ () => {
                      if(!this.state.email.error && !this.state.password.error && !this.state.repeatPassword.error) this.setNewPassword()
                    }}
                  >
                    Reset password { this.state.isLoading ? <>&nbsp;&nbsp;<CircularProgress color="inherit" size={18} /></> : null }
                  </Button>
              }
            </div>
          </RectangularShadowWrap>
        </div>
      </div>
    );
  }
}

export default withRouter(NewPassword);