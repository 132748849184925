import React from 'react';
import styles from './rectangular-shadow-wrap.module.css';

const RectangularShadowWrap = props => {
  return (
    <div className={ styles.wrap } style={ props.style }>
      {props.children}
    </div>
  );
}

export default RectangularShadowWrap;