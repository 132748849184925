import React from 'react';
import RectangularShadowWrap from '../../components/rectangular-shadow-wrap/rectangular-shadow-wrap';
import { withRouter } from 'react-router-dom';

const Error = props => {
  const errorMessage = new URLSearchParams(props.location.search).get("message");

  return (
    <RectangularShadowWrap style={{
      height: "40vh",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      position: "absolute",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -40%)"
    }}>
      <p><b>{ errorMessage }</b></p>
    </RectangularShadowWrap>
  );
}

export default withRouter(Error);